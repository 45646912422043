<template>
  <v-app-bar
      color="white"
      dense
    app
    clipped-left
      elevation="1"
  >
    <v-row justify="center" dense>
      <v-card width="95%" flat color="transparent">
        <v-row justify="space-between" dense>
          <div class="font-weight-black text-h5 mt-2" style="display: inline-block; _display: inline; cursor: pointer;" @click="$router.push('/')"><img src="@/assets/mep_logo_black.png" /></div>
        </v-row>
      </v-card>
    </v-row>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Header',
    data: () => ({
      candidateId: null,
    }),
    methods: {
      viewinfo(val) {
        this.$router.push({ name: "ViewInfo" , params: {id : val}}).catch(() => {});
      },
    },
  }
</script>
