<template>
  <hello />
</template>

<script>
  import Hello from '../components/Hello'

  export default {
    name: 'Home',

    components: {
      Hello,
    },
  }
</script>
