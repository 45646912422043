<template>
  <v-container>
    <v-card v-if="form.isviewform" class="mb-5" flat>
      <v-card-title>銀行口座情報</v-card-title>
    </v-card>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <v-row class="mb-0 pb-0">
          <v-col cols="1" align="right" class="mb-0 pb-0">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5" class="mb-0 pb-0">
            <validation-provider
              v-slot="{ errors }"
              name="銀行コード"
              rules="required|digits:4|max:4"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model="c_bank_code"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="銀行コード"
                placeholder="例）0001"
                hint="数字4桁で入力してください"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right" class="mb-0 pb-0">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5" class="mb-0 pb-0">
            <validation-provider
              v-slot="{ errors }"
              name="支店コード"
              rules="required|digits:3|max:3"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model="c_branch_code"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="支店コード"
                placeholder="例）001"
                hint="数字3桁で入力してください"
              ></v-text-field>

            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
        <v-col cols="1"><v-spacer></v-spacer></v-col>
        <v-col cols="11" class="mt-0 pt-0">
          <v-alert dense outlined type="info" class="text-caption" color="indigo">
            銀行コード、支店コードがご不明な場合は<v-btn small text href="http://bkichiran.hikak.com/" target="_blank" class="pa-0 ma-0 text-decoration-underline text-caption">こちら</v-btn>よりご確認ください
          </v-alert>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="銀行名"
              rules="required|kanji|max:100"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model="c_bank_name"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="銀行名"
                placeholder="例）○○銀行、○○信用金庫"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="支店名"
              rules="required|kanji|max:100"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model="c_branch_name"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="支店名"
                placeholder="例）○○支店"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="預金種類"
              rules="required|oneOf:savings,checking"
            >
              <v-select
                outlined
                v-model="c_account_type"
                :readonly="!inputable"
                :items="accountTypes"
                :error-messages="errors"
                required
                label="預金種類"
              ></v-select>
            </validation-provider>
            </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="口座番号"
              rules="required|digits:7"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model="c_account_number"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="口座番号"
                placeholder="例）0000001"
                hint="数字7桁で入力してください。先頭が0の場合は0もご入力ください"
              ></v-text-field>
            </validation-provider>
            </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="口座名義"
              rules="required|bankaccountnamejp|max:30"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model="c_account_name"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="口座名義"
                placeholder="例）ｶ)ﾐｽﾐ"
                hint="半角ｶﾅ30文字まで。濁点も1文字としてカウントされます"
              ></v-text-field>
            </validation-provider>
            </v-col>
          <v-col cols="6">
          <v-alert dense outlined type="warning" class="ml-3 text-caption" color="deep-orange">
            口座名義ご入力の際の注意事項<br />
            <li>株式会社は、前株→ ｶ)、後株→ (ｶ、中株→ (ｶ) で入力してください</li>
            <li>有限会社は、前株→ ﾕ)、後株→ (ﾕ、中株→ (ﾕ) で入力してください</li>
            <li>個人名の場合は、姓と名の間に半角ｽﾍﾟｰｽを空けてください</li>
            <li>半角カナ小文字（ｧｨｩｪｫｯｬｭｮ）は大文字（ｱｲｳｴｵﾂﾔﾕﾖ）に変換されます</li>
            <li>全角スペース、全角ハイフン、全角ダッシュは半角に変換されます</li>
          </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip v-if="!form.isviewform" color="red" dark small>必須</v-chip>
            <v-chip v-if="form.isviewform" color="grey" outlined small>任意</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="添付ファイル"
              v-bind:rules="getAttachmentRule()"
            >
              <v-file-input
                persistent-hint outlined
                v-model="c_bank_attachment"
                :disabled="!inputable"
                :error-messages="errors"
                chips
                multiple
                label="添付ファイル"
                hint="不正送金防止のため、銀行情報がわかる請求書などのエビデンスの送付をお願いしております"
              ></v-file-input>
            </validation-provider>
            </v-col>
          <v-col v-if="form.isviewform" cols="1" align="right" class="mt-4">
            <div class="grey--text text-caption">【登録済み】</div>
          </v-col>
          <v-col v-if="form.isviewform" cols="5">
            <v-btn
                v-for="(item) of form.bank_attachment_filename"
                :key="item.id"
                class="mr-2 mt-2 custom-transform-class text-none"
                outlined
                color="indigo"
                @click="downloadAttachment(item.id, item.name)"
            >
              <v-icon class="mr-1" small>mdi-file-table-outline</v-icon>
              {{item.name}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
              <v-btn v-if="!form.isviewform" elevation="0" color="white" class="mr-4" @click="$emit('back')">戻る</v-btn>
            <v-btn
              class="mr-4"
              color="blue darken-4"
              outlined
              type="submit"
              :disabled="invalid"
              x-large
            >
              {{ nextbtntext() }}
            </v-btn>
            </v-col>

        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import candidateService from "@/services/candidateService";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as ValidationCustomRules from '@/plugins/vee-validate'
for (const rule in ValidationCustomRules) {
  extend(rule, ValidationCustomRules[rule])
}
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'
import { saveAs } from 'file-saver';

setInteractionMode('eager')


export default {
  name: 'Registry',
  title: '銀行口座情報',
  data: () => ({
    accountTypes: [{text: "普通", value: "savings"},
      {text: "当座", value: "checking"}],
    c_bank_code: String,
    c_branch_code: String,
    c_bank_name: String,
    c_branch_name: String,
    c_account_type: String,
    c_account_number: String,
    c_account_name: String,
    c_bank_attachment: Array,
    c_bank_attachment_filename: Array,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    form: PartnerForm,
    inputable: Boolean,
    bank_code: String,
    branch_code: String,
    bank_name: String,
    branch_name: String,
    account_type: String,
    account_number: String,
    account_name: String,
    bank_attachment: Array,
    bank_attachment_filename: Array,
  },
  computed: {
  },
  methods: {
    submit () {
      this.$refs.observer.validate()
      this.$emit('update:validation_bank', true)

      this.$emit('update:bank_code', this.c_bank_code)
      this.$emit('update:branch_code', this.c_branch_code)
      this.$emit('update:bank_name', this.c_bank_name)
      this.$emit('update:branch_name', this.c_branch_name)
      this.$emit('update:account_type', this.c_account_type)
      this.$emit('update:account_number', this.c_account_number)
      this.$emit('update:account_name', ValidationCustomRules.toAccountNameKana(this.c_account_name))
      this.$emit('update:bank_attachment', this.c_bank_attachment)

      if(this.form.id) {
        this.$emit('confirm')
      } else {
        this.$emit('next')
      }

    },
    nextbtntext() {
      switch (this.form.isviewform) {
        case true:
          return "確認"
        default:
          return "次へ"
      }
    },
    getAttachmentRule() {
      if(!this.form.isviewform) {
        return "required"
      }
    },
    async downloadAttachment(fileid, filename) {

      try{
        const presignedurl= await candidateService.getAttachmentDownloadPresignedUrl(fileid)
        const response = await axios.get(presignedurl, {responseType:'blob'})
            .catch((error) => {
              console.log(error)
            })
        //const filename = presignedurl.match(".+/(.+?)([?#;].*)?$")[1];

        if (response && (200 == response.status)) {
          saveAs(response.data, filename)
        }

      } catch(error) {
        console.log(error)
      }
    }
  },
  async created () {
    this.c_bank_code = this.bank_code
    this.c_branch_code = this.branch_code
    this.c_bank_name = this.bank_name
    this.c_branch_name = this.branch_name
    this.c_account_type = this.account_type
    this.c_account_number = this.account_number
    this.c_account_name = this.account_name
    this.c_bank_attachment = this.bank_attachment
    this.c_bank_attachment_filename = this.bank_attachment_filename
  },
  async mounted(){
    this.$nextTick(function () {
      if (this.form.isviewform == null) {
        switch (this.$route.name) {
          case 'ViewInfo':
            this.$router.push(this.$route.path);
            break
          case 'Registry':
            this.$router.push({hash: '#supplier'})
            break
        }
      } else {
        if (!this.form.isviewform && this.form.id) { // 登録フローかつ登録完了している
          this.$router.push('/viewlist')
        } else if (!this.form.isviewform && (!this.form.validation_supplier || !this.form.validation_person)) { // 登録フローかつ前のステップが欠損している
          this.$router.push({hash: '#supplier'})
        }
      }
    })
  },
}
</script>
