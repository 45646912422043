import Vue from 'vue'
import App from './App.vue'
import titleMixin from '@/util/title'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import awsconfig from './aws-exports';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import { components } from 'aws-amplify-vue'
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import ja from "@aws-amplify-jp/vocabulary-ja";
import  { cognito_messages } from '@/messages/CognitoMessage'
import i18n from './i18n'
import VueClipboard from 'vue-clipboard2'

Amplify.configure(awsconfig);

// 既存mepop-Appsyncの設定を追加
const myAppConfig = {
    'aws_appsync_graphqlEndpoint': process.env.VUE_APP_APPSYNC_ENDPOINT,
    'aws_appsync_region': process.env.VUE_APP_APPSYNC_REGION,
    'aws_appsync_authenticationType': process.env.VUE_APP_APPSYNC_AUTHENTICATIONTYPE
}

Amplify.configure(myAppConfig);

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.config.productionTip = false
Vue.use(VueClipboard)

I18n.putVocabulariesForLanguage("ja", ja(Translations));
I18n.setLanguage('ja');
I18n.putVocabularies(cognito_messages);

Vue.mixin(titleMixin)

new Vue({
  router,
  vuetify,
  store,

  components: { 
    App,
    ...components
  },

  i18n,
  render: h => h(App)
}).$mount('#app')
