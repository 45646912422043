import axios from "axios";
import candidateService from "@/services/candidateService";
import { saveAs } from 'file-saver';

export default {
    async downloadAssetsFile(filename, saveasname) {
        try {
            const presignedurl = await candidateService.getAssetsPresignedUrl(filename)
            const response = await axios.get(presignedurl, { responseType: 'blob' })
                .catch((error) => {
                    console.log(error)
                })
            if (response && (200 == response.status)) {
                saveAs(response.data, saveasname)
            }

        } catch (error) {
            console.log(error)
        }
    },
    async downloadTermsPDF(type) {
        try {
            let filename = ''
            let saveasname = ''
            switch (type) {
                case 'use-and-rules':
                    filename = 'terms/mep_terms_of_use_and_rules.pdf'
                    saveasname = 'meviy Marketplace パートナー利用規約・ルール.pdf'
                    break
                default:
                    filename = 'terms/mep_terms_of_use_and_rules.pdf'
                    saveasname = 'meviy Marketplace パートナー利用規約・ルール.pdf'
            }
            await this.downloadAssetsFile(filename, saveasname)
        } catch (error) {
            console.log(error)
        }
    },
    async downloadGuidePDF() {
        try {
            const filename = 'materials/meviy_partners_guide_for_partners.pdf'
            const saveasname = 'meviy_Marketplaceご案内.pdf'

            await this.downloadAssetsFile(filename, saveasname)
        } catch (error) {
            console.log(error)
        }
    },

}