<script> 
  export default { 
    name: 'PartnerForm', 
    data: () => ({
      isviewform: false,
      istermagreed: false,
      agreement_id: [],
      issent: false,
      id: '',
      regist_user_cognito_sub: '',
      regist_user_name: '',
      regist_user_email: '',
      create_datetime: '',
      update_datetime: '',
      status: 'open',
      validation_supplier: false,
      validation_person: false,
      validation_bank: false,
      validation_invoiceperson: false,
      validation_confirm: false,
      company_name_kanji : '',
      company_name_kana : '',
      zip_cd : '',
      address_1_kanji : '',
      address_2_kanji : '',
      address_1_kana : '',
      address_2_kana : '',
      tel : '',
      fax : '',
      invoice_no: '',
      representative_name : '',
      representative_title : '',
      person_name_sei_kanji : '',
      person_name_mei_kanji : '',
      person_name_sei_kana : '',
      person_name_mei_kana : '',
      buka_name_kanji : '',
      buka_name_kana : '',
      person_tel : '',
      person_fax : '',
      person_email : '',
      person_email_confirm : '',
      bank_code : '',
      branch_code : '',
      bank_name : '',
      branch_name : '',
      account_type :'',
      account_number : '',
      account_name : '',
      bank_attachment : [],
      bank_attachment_filename: [],
      comission_person_name_sei_kanji : '',
      comission_person_name_mei_kanji : '',
      comission_person_name_sei_kana : '',
      comission_person_name_mei_kana : '',
      comission_person_tel : '',
      comission_person_email : '',
      comission_person_email_confirm : '',
      confirm_check: false,
      temporary_save: false,
      org_status : ''
    }),
  }
</script>