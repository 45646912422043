<template>
  <v-container>
    <v-card v-if="form.isviewform" class="mb-5" flat>
      <v-card-title>利用料ご請求先情報</v-card-title>
    </v-card>
    <v-alert dense outlined type="warning" class="mb-10 text-subtitle-2" color="blue-grey darken-2">
      meviy Marketplaceのご利用にあたっては、ミスミから貴社（パートナー様）へご利用状況等に応じて利用料を請求させて頂きます。<br />
      利用料を貴社にご請求し、入金確認等を行うため、利用料専用得意先コード（以下、利用料用得意先コード）が必要となります。<br />
      利用料用得意先コードは、<u>貴社がミスミから商品を購入をされる際に使用する得意先コードとは異なり、商品の購入はできません。</u><br />
      <br />
      下記で登録頂く情報をもとに、利用料用得意先コードに紐づくミスミ販売サイト（WOS）のアカウントを作成します。<br />
      登録頂いたご担当者様宛に手数料明細書や請求書をお送りしますので、請求書等をご確認される方の登録を推奨しております。
    </v-alert>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider v-slot="{ errors }" name="担当者名 姓" rules="required|kanji|max:5">
              <v-text-field persistent-placeholder persistent-hint outlined v-model="c_comission_person_name_sei_kanji"
                :readonly="!inputable" :error-messages="errors" required label="担当者名 姓" placeholder="例）三住"
                hint="5文字まで"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider v-slot="{ errors }" name="担当者名 名" rules="required|kanji|max:5">
              <v-text-field persistent-placeholder persistent-hint outlined v-model="c_comission_person_name_mei_kanji"
                :readonly="!inputable" :error-messages="errors" required label="担当者名 名" placeholder="例）三郎"
                hint="5文字まで"></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider v-slot="{ errors }" name="担当者名 姓（カナ）" rules="required|kanaall|max:10|kanamax:10">
              <v-text-field persistent-placeholder persistent-hint outlined v-model="c_comission_person_name_sei_kana"
                :readonly="!inputable" :error-messages="errors" required label="担当者名 姓（カナ）" placeholder="例）ミスミ"
                hint="カタカナ10文字まで。濁点も1文字としてカウントされます"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider v-slot="{ errors }" name="担当者名 名（カナ）" rules="required|kanaall|max:10|kanamax:10">
              <v-text-field persistent-placeholder persistent-hint outlined v-model="c_comission_person_name_mei_kana"
                :readonly="!inputable" :error-messages="errors" required label="担当者名 名（カナ）" placeholder="例）サブロウ"
                hint="カタカナ10文字まで。濁点も1文字としてカウントされます"></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider v-slot="{ errors }" name="担当者TEL" rules="required|telfaxjp">
              <v-text-field persistent-placeholder persistent-hint outlined v-model.trim="c_comission_person_tel"
                :readonly="!inputable" :error-messages="errors" required label="担当者TEL" placeholder="例）03-5805-7050"
                hint="半角数字とハイフン記号（-）"></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right" class="mb-0 pb-0">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="6" class="mb-0 pb-0">
            <validation-provider v-slot="{ errors }" name="メールアドレス" rules="required|email" vid="invoicepersonemail">
              <v-text-field persistent-placeholder persistent-hint outlined v-model="c_comission_person_email"
                :readonly="!inputable" :error-messages="errors" required label="メールアドレス"
                placeholder="例）taro@misumi.co.jp"></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="5" class="fill-height">
            <v-alert dense outlined class="text-caption" color="deep-orange">
              こちらがミスミ販売サイト（WOS）のログインIDとなります。<br />
              登録後は原則ログインIDの変更はできませんので、ご担当者様が頻繁に変わる場合はメーリングリストの登録を推奨しております。
            </v-alert>
          </v-col>

          <v-col cols="1" align="right" class="mt-0 pt-0">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="6" class="mt-0 pt-0">
            <validation-provider v-slot="{ errors }" name="メールアドレス（確認）" rules="required|confirmed:invoicepersonemail">
              <v-text-field persistent-placeholder persistent-hint outlined v-model="c_comission_person_email_confirm"
                :readonly="!inputable" :error-messages="errors" required label="メールアドレス（確認）"
                placeholder="例）taro@misumi.co.jp" hint="確認のため、再度ご入力ください"></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn v-if="!form.isviewform" elevation="0" color="white" class="mr-4" @click="$emit('back')">戻る</v-btn>
            <v-btn class="mr-4" color="blue darken-4" outlined type="submit" :disabled="invalid" x-large>
              {{ nextbtntext() }}
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as ValidationCustomRules from '@/plugins/vee-validate'
for (const rule in ValidationCustomRules) {
  extend(rule, ValidationCustomRules[rule])
}
import '@mdi/font/css/materialdesignicons.css'

setInteractionMode('eager')

export default {
  name: 'Registry',
  title: '利用料ご請求先情報',
  data: () => ({
    c_comission_person_name_sei_kanji: String,
    c_comission_person_name_mei_kanji: String,
    c_comission_person_name_sei_kana: String,
    c_comission_person_name_mei_kana: String,
    c_comission_person_tel: String,
    c_comission_person_email: String,
    c_comission_person_email_confirm: String,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    form: PartnerForm,
    inputable: Boolean,
    comission_person_name_sei_kanji: String,
    comission_person_name_mei_kanji: String,
    comission_person_name_sei_kana: String,
    comission_person_name_mei_kana: String,
    comission_person_tel: String,
    comission_person_email: String,
    comission_person_email_confirm: String,
  },
  computed: {
  },
  methods: {
    submit() {
      this.$refs.observer.validate()
      this.$emit('update:validation_invoiceperson', true)

      this.$emit('update:comission_person_name_sei_kanji', this.c_comission_person_name_sei_kanji)
      this.$emit('update:comission_person_name_mei_kanji', this.c_comission_person_name_mei_kanji)
      this.$emit('update:comission_person_name_sei_kana', ValidationCustomRules.hiraToKana(this.c_comission_person_name_sei_kana))
      this.$emit('update:comission_person_name_mei_kana', ValidationCustomRules.hiraToKana(this.c_comission_person_name_mei_kana))
      this.$emit('update:comission_person_tel', this.c_comission_person_tel)
      this.$emit('update:comission_person_email', this.c_comission_person_email)
      this.$emit('update:comission_person_email_confirm', this.c_comission_person_email_confirm)

      this.$emit('next')
    },
    nextbtntext() {
      switch (this.form.isviewform) {
        case true:
          return "確認"
        default:
          return "次へ"
      }
    },
  },
  async created() {
    this.c_comission_person_name_sei_kanji = this.comission_person_name_sei_kanji
    this.c_comission_person_name_mei_kanji = this.comission_person_name_mei_kanji
    this.c_comission_person_name_sei_kana = this.comission_person_name_sei_kana
    this.c_comission_person_name_mei_kana = this.comission_person_name_mei_kana
    this.c_comission_person_tel = this.comission_person_tel
    this.c_comission_person_email = this.comission_person_email
    this.c_comission_person_email_confirm = this.comission_person_email_confirm
  },
  async mounted() {
    this.$nextTick(function () {
      if (this.form.isviewform == null) {
        switch (this.$route.name) {
          case 'ViewInfo':
            this.$router.push(this.$route.path);
            break
          case 'Registry':
            this.$router.push({ hash: '#supplier' })
            break
        }
      } else {
        if (!this.form.isviewform && this.form.id) { // 登録フローかつ登録完了している
          this.$router.push('/viewlist')
        } else if (!this.form.isviewform && (!this.form.validation_supplier || !this.form.validation_person || !this.form.validation_bank)) { // 登録フローかつ前のステップが欠損している
          this.$router.push({ hash: '#supplier' })
        }
      }
    })
  },
}
</script>
