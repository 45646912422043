<template>
  <v-container
      class="py-8 px-6"
      fluid
  >
    <Header />

    <v-navigation-drawer
        v-model="drawer"
        app
        permanent
        clipped
    >
      <v-sheet
          color="grey lighten-4"
          class="pa-4"
      >
        <v-row dense>
          <v-col cols="7" class="grey--text">ログイン情報</v-col>
          <v-col cols="12" style="word-break : break-all;">{{ signinemail }}</v-col>
          <v-col cols="12">{{ signinname }} 様</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="7" class="grey--text">受付番号</v-col>
          <v-col cols="12" class="indigo--text caption">{{ id }} </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="7" class="grey--text">ステータス</v-col>
          <v-col cols="12" class="indigo--text caption">
            <v-chip v-if="this.form.status=='open'" color="success" outlined class="ma-0" small>{{ $t(this.form.status) }}</v-chip>
            <v-chip v-else color="success" outlined class="ma-0" small>{{ $t(this.form.status) }}</v-chip>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-group
            v-model="selectedItem"
            color="grey"
            active-class="indigo lighten-5 black--text"
        >
          <v-list-item
              v-for="[icon, text, hash] in links"
              :key="hash"
              link
              @click="$router.push({ hash: hash })"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              <div class="caption">{{ text }}</div>
            </v-list-item-title>
            <v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <transition :name="transitionName" mode="out-in">
      <!-- susPageの値に応じてコンポーネントを切り替えて、擬似的にページ遷移を表現 -->
      <component
          :is="subPage"
          :form="form"
          :inputable.sync="inputable"
          :validation_supplier.sync="form.validation_supplier"
          :validation_person.sync="form.validation_person"
          :validation_bank.sync="form.validation_bank"
          :validation_invoiceperson.sync="form.validation_invoiceperson"
          :validation_confirm.sync="form.validation_confirm"
          :company_name_kanji.sync="form.company_name_kanji"
          :company_name_kana.sync="form.company_name_kana"
          :zip_cd.sync="form.zip_cd"
          :address_1_kanji.sync="form.address_1_kanji"
          :address_2_kanji.sync="form.address_2_kanji"
          :address_1_kana.sync="form.address_1_kana"
          :address_2_kana.sync="form.address_2_kana"
          :tel.sync="form.tel"
          :fax.sync="form.fax"
          :invoice_no.sync="form.invoice_no"
          :representative_name.sync="form.representative_name"
          :representative_title.sync="form.representative_title"
          :person_name_sei_kanji.sync="form.person_name_sei_kanji"
          :person_name_mei_kanji.sync="form.person_name_mei_kanji"
          :person_name_sei_kana.sync="form.person_name_sei_kana"
          :person_name_mei_kana.sync="form.person_name_mei_kana"
          :buka_name_kanji.sync="form.buka_name_kanji"
          :buka_name_kana.sync="form.buka_name_kana"
          :person_tel.sync="form.person_tel"
          :person_fax.sync="form.person_fax"
          :person_email.sync="form.person_email"
          :person_email_confirm.sync="form.person_email_confirm"
          :bank_code.sync="form.bank_code"
          :branch_code.sync="form.branch_code"
          :bank_name.sync="form.bank_name"
          :branch_name.sync="form.branch_name"
          :account_type.sync="form.account_type"
          :account_number.sync="form.account_number"
          :account_name.sync="form.account_name"
          :bank_attachment.sync="form.bank_attachment"
          :comission_person_name_sei_kanji.sync="form.comission_person_name_sei_kanji"
          :comission_person_name_mei_kanji.sync="form.comission_person_name_mei_kanji"
          :comission_person_name_sei_kana.sync="form.comission_person_name_sei_kana"
          :comission_person_name_mei_kana.sync="form.comission_person_name_mei_kana"
          :comission_person_tel.sync="form.comission_person_tel"
          :comission_person_email.sync="form.comission_person_email"
          :comission_person_email_confirm.sync="form.comission_person_email_confirm"
          :temporary_save.sync="form.temporary_save"
          @back="back"
          @next="next"
          @supplier="supplier"
          @person="person"
          @bank="bank"
          @invoice="invoice"
          @confirm="confirm"
          @register="register"
      ></component>
    </transition>
  </v-container>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import PartnerForm from '@/forms/PartnerForm.vue'
import SupplierForm from '@/components/SupplierForm.vue'
import PersonForm from '@/components/PersonForm.vue'
import BankForm from '@/components/BankForm.vue'
import InvoicePersonForm from '@/components/InvoicePersonForm.vue'
import Confirm from '@/components/Confirm.vue'
import Complete from '@/components/Complete.vue'
import candidateService from "@/services/candidateService";
import store from '@/store/index.js'
import Header from "@/components/Header";

export default {
  name: 'ViewInfo',
  components: { Header },
  data: () => ({
    form: PartnerForm,
    transitionName: '',
    drawer: null,
    selectedItem: '',
    signinname: store.state.user.attributes.name,
    signinemail: store.state.user.attributes.email,
    candidateHeaders: null,
    inputable: false,
    links: [
      ['mdi-factory', '会社・代表者情報', '#supplier'],
      ['mdi-account', '担当者情報', '#person'],
      ['mdi-bank', '銀行口座情報', '#bank'],
      ['mdi-receipt', '利用料ご請求先情報', '#invoice'],
      ['mdi-send', '内容確認と送信', '#confirm'],
    ],
  }),
  props: {
    id: String,
  },
  computed: {
    subPage () {
      this.getSelectedItem()
      // URLのhashの値に基づいて、返すコンポーネントを切り替え
      switch (this.$route.hash) {
        case '#supplier':
          return SupplierForm
        case '#person':
          return PersonForm
        case '#bank':
          return BankForm
        case '#invoice':
          return InvoicePersonForm
        case '#confirm':
          return Confirm
        case '#complete':
          return Complete
        default:
          return Confirm
      }
    },
  },
  methods: {
    supplier () {
      this.transitionName = 'slide-back'
      this.$router.push({ hash: '#supplier' })
    },
    person () {
      this.transitionName = 'slide-back'
      this.$router.push({ hash: '#person' })
    },
    bank () {
      this.transitionName = 'slide-back'
      this.$router.push({ hash: '#bank' })
    },
    invoice () {
      this.transitionName = 'slide-back'
      this.$router.push({ hash: '#invoice' })
    },
    confirm () {
      this.transitionName = 'slide-back'
      this.$router.push({ hash: '#confirm' })
    },
    back () {
      this.transitionName = 'slide-back'
      switch (this.$route.hash) {
        default:
          this.$router.push('/viewinfo')
          break
      }
    },
    next () {
      this.transitionName = 'slide-next'
      switch (this.$route.hash) {
        default:
          this.$router.push({ hash: '#confirm' })
          break
      }
    },
    getSelectedItem () {
      switch (this.$route.hash) {
        case '#supplier':
          this.selectedItem = 0
          break
        case '#person':
          this.selectedItem = 1
          break
        case '#bank':
          this.selectedItem = 2
          break
        case '#invoice':
          this.selectedItem = 3
          break
        case '#confirm':
          this.selectedItem = 4
          break
        default:
          this.selectedItem = ''
      }
    },
    async register () {
      const result = await candidateService.updatePartnerCandidate(this.form)
      this.form.id = result.id
      this.form.status = result.status
      this.form.issent = result.issent
      this.inputable = (this.form.status != 'open' && this.form.status != 'processing' && this.form.status != 'done')
      if(this.form.id) {
        this.$router.push({hash: '#complete'})
      }
    }
  },
  async created () {
    this.form =  await candidateService.getPartnerCandidateById(this.id, store.state.user.attributes.sub)
    this.form.isviewform = true
    this.inputable = (this.form.status != 'open' && this.form.status != 'processing' && this.form.status != 'done')
  }
}
</script>
