<template>
  <v-container
    class="py-8 px-6"
    fluid
  >
    <Header />
    <v-navigation-drawer
      v-model="drawer"
      app
      permanent
      clipped
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-4"
      >
        <v-row dense>
          <v-col cols="7" class="grey--text caption">ログイン情報</v-col>
          <v-col cols="12">{{ signinname }} 様</v-col>
          <v-col cols="12" style="word-break : break-all;">{{ signinemail }}</v-col>
        </v-row>
      </v-sheet>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="[icon, text, no] in links"
          :key="icon"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          
          <v-list-item-title>
            <v-chip v-if="no == '' && progressNo == 6" color="blue darken-4" class="ma-0" dark x-small>送信済</v-chip>
            <v-chip v-else-if="no == ''" color="grey" outlined class="ma-0" x-small>未送信</v-chip>
            <v-chip v-else-if="validated(no)" color="blue darken-4" class="ma-0" dark x-small>入力済</v-chip>
            <v-chip v-else-if="progressNo == no" color="blue darken-4" class="ma-0" dark outlined x-small>入力中</v-chip>
            <v-chip v-else color="grey" outlined class="ma-0" x-small>未入力</v-chip>
            <br/><div class="caption">{{ text }}</div>
          </v-list-item-title>
          <v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

  <v-stepper flat class="mb-2 caption" x-small v-model="progressNo">
    <v-stepper-header>
      <v-stepper-step :complete="progressNo > 1" step="1">
        会社・代表者情報
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="progressNo > 2" step="2">
        担当者情報
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="progressNo > 3" step="3">
        銀行口座情報
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="progressNo > 4" step="4">
        利用料ご請求先情報
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="progressNo > 5" step="5">
        入力内容確認
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="progressNo > 6" step="6">
        申請送信完了
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
    <transition :name="transitionName" mode="out-in">
      <!-- susPageの値に応じてコンポーネントを切り替えて、擬似的にページ遷移を表現 -->
      <component
        :is="subPage"
        :form="form"
        :inputable="true"
        :istermagreed.sync="form.istermagreed"
        :agreement_id.sync="form.agreement_id"
        :validation_supplier.sync="form.validation_supplier"
        :validation_person.sync="form.validation_person"
        :validation_bank.sync="form.validation_bank"
        :validation_invoiceperson.sync="form.validation_invoiceperson"
        :validation_confirm.sync="form.validation_confirm"
        :company_name_kanji.sync="form.company_name_kanji"
        :company_name_kana.sync="form.company_name_kana"
        :zip_cd.sync="form.zip_cd"
        :address_1_kanji.sync="form.address_1_kanji"
        :address_2_kanji.sync="form.address_2_kanji"
        :address_1_kana.sync="form.address_1_kana"
        :address_2_kana.sync="form.address_2_kana"
        :tel.sync="form.tel"
        :fax.sync="form.fax"
        :invoice_no.sync="form.invoice_no"
        :representative_name.sync="form.representative_name"
        :representative_title.sync="form.representative_title"
        :person_name_sei_kanji.sync="form.person_name_sei_kanji"
        :person_name_mei_kanji.sync="form.person_name_mei_kanji"
        :person_name_sei_kana.sync="form.person_name_sei_kana"
        :person_name_mei_kana.sync="form.person_name_mei_kana"
        :buka_name_kanji.sync="form.buka_name_kanji"
        :buka_name_kana.sync="form.buka_name_kana"
        :person_tel.sync="form.person_tel"
        :person_fax.sync="form.person_fax"
        :person_email.sync="form.person_email"
        :person_email_confirm.sync="form.person_email_confirm"
        :bank_code.sync="form.bank_code"
        :branch_code.sync="form.branch_code"
        :bank_name.sync="form.bank_name"
        :branch_name.sync="form.branch_name"
        :account_type.sync="form.account_type"
        :account_number.sync="form.account_number"
        :account_name.sync="form.account_name"
        :bank_attachment.sync="form.bank_attachment"
        :comission_person_name_sei_kanji.sync="form.comission_person_name_sei_kanji"
        :comission_person_name_mei_kanji.sync="form.comission_person_name_mei_kanji"
        :comission_person_name_sei_kana.sync="form.comission_person_name_sei_kana"
        :comission_person_name_mei_kana.sync="form.comission_person_name_mei_kana"
        :comission_person_tel.sync="form.comission_person_tel"
        :comission_person_email.sync="form.comission_person_email"
        :comission_person_email_confirm.sync="form.comission_person_email_confirm"
        :temporary_save.sync="form.temporary_save"
        @back="back"
        @next="next"
        @first="first"
        @supplier="supplier"
        @person="person"
        @bank="bank"
        @invoice="invoice"
        @confirm="confirm"
        @register="register"
        @agreeTerms="agreeTerms"
      ></component>
    </transition>
  </v-container>
</template>

<script>
  import '@mdi/font/css/materialdesignicons.css'
  import PartnerForm from '@/forms/PartnerForm.vue'
  import SupplierForm from '@/components/SupplierForm.vue'
  import PersonForm from '@/components/PersonForm.vue'
  import BankForm from '@/components/BankForm.vue'
  import InvoicePersonForm from '@/components/InvoicePersonForm.vue'
  import Confirm from '@/components/Confirm.vue'
  import Complete from '@/components/Complete.vue'
  import store from '@/store/index.js'
  import candidateService from "@/services/candidateService";
  import Header from "@/components/Header";
  import * as formUtil from '@/forms/partnerformutil'

  export default {
    name: 'Registry',
    components: { Header },
    data: () => ({
      form: PartnerForm,
      transitionName: '',
      drawer: null,
      signinname: store.state.user.attributes.name,
      signinemail: store.state.user.attributes.email,
      links: [
        ['mdi-factory', '会社・代表者情報', '1', PartnerForm.validation_supplier],
        ['mdi-account', '担当者情報', '2', PartnerForm.validation_person],
        ['mdi-bank', '銀行口座情報', '3', PartnerForm.validation_bank],
        ['mdi-receipt', '利用料ご請求先情報', '4', PartnerForm.validation_invoiceperson],
        ['mdi-send', '内容確認と送信', '', PartnerForm.validation_confirm],
      ],
    }),
    computed: {
      subPage () {
        // URLのhashの値に基づいて、返すコンポーネントを切り替え
        switch (this.$route.hash) {
          case '#supplier':
            return SupplierForm
          case '#person':
            return PersonForm
          case '#bank':
            return BankForm
          case '#invoice':
            return InvoicePersonForm
          case '#confirm':
            return Confirm
          case '#complete':
            return Complete
          default:
            return SupplierForm
        }
      },
      progressNo () {
        switch (this.$route.hash) {
          case '#supplier':
            return 1
          case '#person':
            return 2
          case '#bank':
            return 3
          case '#invoice':
            return 4
          case '#confirm':
            return 5
          case '#complete':
            return 6
          default:
            return 1
        }
      }
    },
    methods: {
      validated (no) {
        switch (no) {
          case '1':
            return this.form.validation_supplier
          case '2':
            return this.form.validation_person
          case '3':
            return this.form.validation_bank
          case '4':
            return this.form.validation_invoiceperson
          case '5':
            return this.form.validation_confirm
          default:
            return false
        }
      },
      first () {
        this.transitionName = 'slide-back'
        this.$router.push({ hash: '#supplier' })
      },
      supplier () {
        this.transitionName = 'slide-back'
        this.$router.push({ hash: '#supplier' })
      },
      person () {
        this.transitionName = 'slide-back'
        this.$router.push({ hash: '#person' })
      },
      bank () {
        this.transitionName = 'slide-back'
        this.$router.push({ hash: '#bank' })
      },
      invoice () {
        this.transitionName = 'slide-back'
        this.$router.push({ hash: '#invoice' })
      },
      confirm () {
        this.transitionName = 'slide-back'
        this.$router.push({ hash: '#confirm' })
      },
      back () {
        this.transitionName = 'slide-back'
        switch (this.$route.hash) {
          case '#person':
            this.$router.push({ hash: '#supplier' })
            break
          case '#bank':
            this.$router.push({ hash: '#person' })
            break
          case '#invoice':
            this.$router.push({ hash: '#bank' })
            break
          case '#confirm':
            this.$router.push({ hash: '#invoice' })
            break
        }
      },
      next () {
        this.transitionName = 'slide-next'
        switch (this.$route.hash) {
          case '#person':
            this.$router.push({ hash: '#bank' })
            break
          case '#bank':
            this.$router.push({ hash: '#invoice' })
            break
          case '#invoice':
            this.$router.push({ hash: '#confirm' })
            break
          case '#confirm':
            this.$router.push({ hash: '#complete' })
            break
          default:
            this.$router.push({ hash: '#person' })
            break
        }
      },

      async getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      async register () {
        const result = await candidateService.registerPartnerCandidate(this.form)

        this.form.id = result.id
        this.form.status = result.status
        this.form.issent = result.issent

        if(this.form.id) {
          this.$router.push({hash: '#complete'})
        }
      },
      async agreeTerms () {
        const agreement_id = await candidateService.agreeTerms([{type:'use-and-rules', version:'MEP-P-1.5'}])
        this.form.istermagreed = true
        this.form.agreement_id = agreement_id
      },
    },
    async created () {
      this.form = formUtil.resetPartnerForm(this.form)
      this.form.isviewform = false
    }
  }
</script>
