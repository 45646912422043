var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.form.isviewform)?_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("利用料ご請求先情報")])],1):_vm._e(),_c('v-alert',{staticClass:"mb-10 text-subtitle-2",attrs:{"dense":"","outlined":"","type":"warning","color":"blue-grey darken-2"}},[_vm._v(" meviy Marketplaceのご利用にあたっては、ミスミから貴社（パートナー様）へご利用状況等に応じて利用料を請求させて頂きます。"),_c('br'),_vm._v(" 利用料を貴社にご請求し、入金確認等を行うため、利用料専用得意先コード（以下、利用料用得意先コード）が必要となります。"),_c('br'),_vm._v(" 利用料用得意先コードは、"),_c('u',[_vm._v("貴社がミスミから商品を購入をされる際に使用する得意先コードとは異なり、商品の購入はできません。")]),_c('br'),_c('br'),_vm._v(" 下記で登録頂く情報をもとに、利用料用得意先コードに紐づくミスミ販売サイト（WOS）のアカウントを作成します。"),_c('br'),_vm._v(" 登録頂いたご担当者様宛に手数料明細書や請求書をお送りしますので、請求書等をご確認される方の登録を推奨しております。 ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 姓","rules":"required|kanji|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 姓","placeholder":"例）三住","hint":"5文字まで"},model:{value:(_vm.c_comission_person_name_sei_kanji),callback:function ($$v) {_vm.c_comission_person_name_sei_kanji=$$v},expression:"c_comission_person_name_sei_kanji"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 名","rules":"required|kanji|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 名","placeholder":"例）三郎","hint":"5文字まで"},model:{value:(_vm.c_comission_person_name_mei_kanji),callback:function ($$v) {_vm.c_comission_person_name_mei_kanji=$$v},expression:"c_comission_person_name_mei_kanji"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 姓（カナ）","rules":"required|kanaall|max:10|kanamax:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 姓（カナ）","placeholder":"例）ミスミ","hint":"カタカナ10文字まで。濁点も1文字としてカウントされます"},model:{value:(_vm.c_comission_person_name_sei_kana),callback:function ($$v) {_vm.c_comission_person_name_sei_kana=$$v},expression:"c_comission_person_name_sei_kana"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 名（カナ）","rules":"required|kanaall|max:10|kanamax:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 名（カナ）","placeholder":"例）サブロウ","hint":"カタカナ10文字まで。濁点も1文字としてカウントされます"},model:{value:(_vm.c_comission_person_name_mei_kana),callback:function ($$v) {_vm.c_comission_person_name_mei_kana=$$v},expression:"c_comission_person_name_mei_kana"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者TEL","rules":"required|telfaxjp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者TEL","placeholder":"例）03-5805-7050","hint":"半角数字とハイフン記号（-）"},model:{value:(_vm.c_comission_person_tel),callback:function ($$v) {_vm.c_comission_person_tel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_comission_person_tel"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"メールアドレス","rules":"required|email","vid":"invoicepersonemail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"メールアドレス","placeholder":"例）taro@misumi.co.jp"},model:{value:(_vm.c_comission_person_email),callback:function ($$v) {_vm.c_comission_person_email=$$v},expression:"c_comission_person_email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"5"}},[_c('v-alert',{staticClass:"text-caption",attrs:{"dense":"","outlined":"","color":"deep-orange"}},[_vm._v(" こちらがミスミ販売サイト（WOS）のログインIDとなります。"),_c('br'),_vm._v(" 登録後は原則ログインIDの変更はできませんので、ご担当者様が頻繁に変わる場合はメーリングリストの登録を推奨しております。 ")])],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"メールアドレス（確認）","rules":"required|confirmed:invoicepersonemail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"メールアドレス（確認）","placeholder":"例）taro@misumi.co.jp","hint":"確認のため、再度ご入力ください"},model:{value:(_vm.c_comission_person_email_confirm),callback:function ($$v) {_vm.c_comission_person_email_confirm=$$v},expression:"c_comission_person_email_confirm"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[(!_vm.form.isviewform)?_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0","color":"white"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("戻る")]):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"blue darken-4","outlined":"","type":"submit","disabled":invalid,"x-large":""}},[_vm._v(" "+_vm._s(_vm.nextbtntext())+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }