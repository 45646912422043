<template>
  <v-container
      class="py-8 px-6"
      fluid
  >
    <Header />

    <v-row class="text-center" justify="center">

      <div class="signin">
        <div v-if="!signedIn">
          <amplify-authenticator v-bind:authConfig="authConfig"></amplify-authenticator>
        </div>
        <div v-if="signedIn">
          <amplify-sign-out></amplify-sign-out>
        </div>
      </div>
    </v-row>
  </v-container>
</template>



<script>
/* eslint-disable no-console */
import { AmplifyEventBus } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'
import Header from "@/components/Header";

export default {
  name: 'SignIn',
  components: { Header },
  data () {
    return {
      signedIn: false,
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false
        },
        signUpConfig: {
          hideAllDefaults: true,
          signUpFields: [
            {
              label: 'Name',
              key: 'name',
              required: true,
              displayOrder: 1,
              type: 'string',
            },
            {
              label: 'EMail',
              key: 'email',
              required: true,
              displayOrder: 1,
              type: 'string',
            },
            {
              label: 'Password',
              key: 'password',
              required: true,
              displayOrder: 2,
              type: 'password'
            }
          ]
        }
      }
    }
  },
  async beforeCreate() {
    try {
      await Auth.currentAuthenticatedUser()
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.signedIn = true
      } else {
        this.signedIn = false
      }
    })
  }
}
</script>
<style>
.signin {
  margin: 20px;
}
input {
  font-size: 16px !important;
}
@import '../assets/cognito.css';
@media screen and (max-width: 480px) {
  .signin div {
    min-width: 0;
  }
}
</style>