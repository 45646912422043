<template>
  <v-container
      class="py-8 px-6"
      fluid
  >
    <Header />
    <v-navigation-drawer
        v-model="drawer"
        app
        permanent
        clipped
    >
      <v-sheet
          color="grey lighten-4"
          class="pa-4"
      >
        <v-row dense>
          <v-col class="grey--text caption">ログイン情報</v-col>
        </v-row>
        <v-row dense>
          <v-col>{{ signinname }} 様</v-col>
        </v-row>
        <v-row dense>
          <v-col>{{ signinemail }}</v-col>
        </v-row>
      </v-sheet>
      <v-divider></v-divider>
    </v-navigation-drawer>

    <v-layout row wrap>
      <v-card flat width="90%">
        <v-card-title>
          パートナー新規登録申請
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div class="my-4 subtitle-1">
            申請内容の詳細を確認したい場合は、「確認」ボタンを押してください。<br />
            ステータスが「受付」となっている場合は内容の修正が可能です。<br />
            それ以外のステータスで修正の必要がある場合は、恐れ入りますが以下のアドレスまで受付番号と合わせて修正内容のご連絡をお願いいたします。
          </div>
          <div class="my-4 subtitle-2">
            お問い合わせ受付アドレス：meviy-marketplace-support@misumi.co.jp
            <v-icon small @click="doCopy('meviy-marketplace-support@misumi.co.jp')">mdi-content-copy</v-icon>
            <v-chip v-if="showcopy" small>
              コピーしました
            </v-chip>
          </div>
        </v-card-text>
        <v-data-table
            v-if="candidateHeaders"
            :headers="headers"
            :items="candidateHeaders"
            :disable-pagination=true
            :disable-sort=true
            hide-default-footer
            class="elevation-1"
            item-key="id"
        >
          <template v-for="header in headers.filter(
              (header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ header, value }">
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip color="success" outlined class="ma-0" small>{{ $t(item.status) }}</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn @click="viewinfo(item.id)">
              確認
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import candidateService from "@/services/candidateService";
import store from '@/store/index.js'
import Header from "@/components/Header";

export default {
  name: 'ViewList',
  title: '申請内容確認',
  components: { Header },
  data: () => ({
    drawer: null,
    signinname: store.state.user.attributes.name,
    signinemail: store.state.user.attributes.email,
    headers: null,
    candidateHeaders: null,
    showcopy: false,
  }),
  methods: {
    viewinfo(val) {
      this.$router.push({ name: "ViewInfo" , params: {id : val}}).catch(() => {});
    },
    doCopy(value) {
      if(value) {
        this.$copyText(value)
        this.showcopy = true
      }
    },
  },
  async mounted () {
    this.resCandidates =  await candidateService.getPartnerCandidateHeaderByRegistUserSub(store.state.user.attributes.sub)
    this.headers = this.resCandidates.headers
    this.candidateHeaders = this.resCandidates.partnercandidates
    this.showcopy = false
  }
}
</script>
