<template>
  <v-card class="ma-5" flat>

    <v-card-title class="text-h5 ma-5">
      meviy Marketplaceパートナー利用規約
    </v-card-title>

    <div class="text-body-2 ma-10">
      株式会社ミスミ（以下、ミスミといいます）が提供する本プラットフォーム（第１条で定義します。）を利用して機械部品等の製造委託・販売又はサービス等を受託しようとする方（以下、パートナーといいます。）は、このmeviy
      Marketplaceパートナー利用規約（以下、本規約といいます。）にご同意いただく必要があります。なお、ミスミは、本規約のほか、本プラットフォーム上の各サービスの利用に関するルール又は「パートナー利用マニュアル」等のマニュアル等（以下、ルール等といいます。）を設ける場合があります。パートナーが各サービスをご利用された場合は、本規約及びルール等にも同意をしたものとみなされます。
    </div>

    <div class="text-h6 ma-5">
      第１条 （定義）
    </div>
    <div class="text-body-2 ma-10">
      本規約で使用する用語の意義は、以下のとおりとします。<br />
      (1) 「本ウェブサイト」とは、以下のドメインに含まれる全てのディレクトリ、サブドメイン、及びページをいいます。<br />
      <div class="text-body-2 ma-2 ml-5">marketplace.meviy.misumi-ec.com<br /></div>
      <div class="text-body-2 ma-2 ml-5">partners.meviy.io<br /></div>
      (2) 「本プラットフォーム」とは、機械部品等の製造委託、購入又はサービス等を委託する若しくは委託しようとするために、ミスミが顧客及びパートナーに提供する機能の総称をいい、これをmeviy
      Marketplaceと呼称します。<br />
      (3) 「ＭＭサービス」とは、本プラットフォームにおいてミスミが提供する各種サービス及び機能の総称をいいます。<br />
      (4) 「本製品」とは、本プラットフォームを利用してパートナーが製造委託又は販売取引を行う機械部品等をいいます。<br />
      (5) 「本役務」とは、本プラットフォームを利用してパートナーが受託する各種サービスをいいます。<br />
      (6) 「本成果物」とは、本役務により製作された物、報告書、プログラム、その他データ等をいいます。なお、本製品と本成果物を合わせて、「本製品等」といいます。<br />
      (7) 「顧客」とは、本プラットフォームを利用して、パートナーに対し本製品又は本役務の見積を依頼する者、発注を行う者、又は発注を行おうとする者をいいます。<br />
      (8) 「パートナー向けサービス」とは、ＭＭサービスのうち、ミスミがパートナーに提供する各種サービス及び機能の総称をいいます。<br />
      (9) 「個別契約」とは、パートナーと顧客間で締結する個々の本製品並びに本役務毎の請負契約、委託契約及び売買契約をいいます。<br />
      (10) 「発注」とは、個別契約の申込みをいいます。<br />
      (11) 「委託料等」とは、顧客からパートナーに支払われる個別契約の対価及びそれに付随する諸費用をいいます。<br />
      (12)
      「代理受領」とは、ミスミがパートナーに代わって、本規約第１５条に定める委託料等及び委託料等にかかる消費税その他の税金を含む金銭を顧客又は顧客に代わり当該金銭を立替払いする者（決済代行業者を含む）から受領することをいいます。<br />
      (13) 「ミスミの販売サイト」とは、ミスミが顧客に提供する見積及び発注システムをいいます。<br />
      (14) 「ミスミ利用規約」とは、以下のミスミのウェブサイト（URL
      を変更した場合は別途ウェブサイト上で掲載します。）に掲載される利用規約をいいます。本プラットフォーム上の各サービスの利用については本規約及びルール等が優先的に適用されます。ただし、本規約及びルール等に定めのない事項については、本規約及びルール等と矛盾しない限りにおいて、本プラットフォーム上の各サービスの利用に関し、ミスミ利用規約も適用されるものとします。<br />
      <div class="text-body-2 ma-2 ml-5">https://jp.misumiec.com/contents/terms/use.html<br /></div>
      (15) 「お客様コードＮｏ．」とは、ミスミ利用規約にて定められた当社の会員登録手続により顧客単位で発行されるものをいいます。<br />
    </div>
    <div class="text-h6 ma-5">
      第２条 （パートナー向けサービス）
    </div>
    <div class="text-body-2 ma-10">
      １ ミスミは、パートナーに対し、本プラットフォーム上で以下のパートナー向けサービスを提供します。<br />
      (1) パートナー及びサービス等に関する説明コンテンツの作成及び公開機能<br />
      (2) 個別契約の見積及び受注機能<br />
      (3) 個別契約の代金決済機能<br />
      (4) 顧客とのコミュニケーション履歴の管理機能<br />
      (5) 取引情報管理機能<br />
      (6) マーケティングサービスの提供<br />
      (7) その他(1)から（6）に関連してミスミが別途開発する各種サービス及び機能<br />
      ２ 本ウェブサイト上で締結される個別契約は、パートナーと顧客が契約当事者となります。ミスミは、ＭＭサービス提供者として、本規約又は別段の定めに従い、個別契約に関与します。<br />
      ３ 本規約に基づいてミスミが本プラットフォームを提供するエリアは、日本国内とします。<br />
    </div>
    <div class="text-h6 ma-5">
      第３条 （利用登録）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナーは、本プラットフォームの利用開始にあたり、ミスミ所定の手続に従い、利用登録申請を行います。<br />
      ２
      ミスミが前項の登録申請を承認し、登録手続が完了した後、ミスミは、パートナーにパートナーアカウントを発行します。パートナーは、パートナーアカウントが発行された後、本ウェブサイト上で本第３条第１項所定の各サービスの利用を開始することができます。<br />
      ３ ミスミは、利用登録申請を行ったパートナーが以下の事由に該当した場合には、当該利用登録申請を承認しないことがあります。<br />
      (1) 法人又は個人事業主としての登録ではない場合<br />
      (2) 既に利用登録が承認されている場合<br />
      (3) 利用登録申請を行う方が日本国外に所在する場合<br />
      (4) 過去に利用登録申請の不承認又は本プラットフォームの利用登録の取消をされたことがある場合<br />
      (5) 利用登録申請の申込内容に虚偽の事項が含まれている場合又は虚偽の事項が含まれている疑いのある場合<br />
      (6) 機械部品の製造委託及び販売並びにこれらに関するサービスを提供すること以外の目的で利用登録申請をする場合又はその疑いがある場合<br />
      (7) 第４条第1項各号に該当する又はそのおそれがある場合<br />
      (8) その他利用登録を承認することが不適当であるとミスミが判断する場合<br />
      ４ パートナーは、第1項の登録申請時にミスミに届け出た情報に変更があったときは、直ちにミスミにその旨を通知しなければなりません。なお、当該の通知が無い又は遅延したことにより生じた損害は、パートナーにご負担頂くものとします。
    </div>
    <div class="text-h6 ma-5">
      第４条 （登録の取消、利用の中断等）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナーに以下の各号に該当する事由が生じた場合、ミスミは、パートナーの利用登録の取消を行うこと、パートナーへの予告なしに本プラットフォームの利用の全部若しくは一部の中断又は停止することができるものとします。<br />
      (1) パートナーがミスミに届け出た情報が不正確又は虚偽であることが判明したとき<br />
      (2) 第９条の禁止事項に該当する行為が判明したとき<br />
      (3)
      パートナーが、ＭＭサービスを利用する上で第１０条で定める株式会社DGフィナンシャルテクノロジー（以下「DGFT」といいます。）との収納代行契約及び決済事業者との加盟店契約等に関する初期（契約時）審査又は加盟店契約後の途上審査の審査不合格、利用の中断若しくは停止、又は登録の取消しを受けたとき<br />
      (4) パートナーが本プラットフォームの利用により生じた債務を遅滞し又は怠り、ミスミが通知により是正を求めたにも関わらず、７日以内に是正しないとき<br />
      (5) 前号のほか、パートナーが本規約、個別契約又はその他本プラットフォームに関する契約、ルール等の各条項の１つにでも違反し、ミスミが通知により是正を求めたにも関わらず、合理的な期間経過後もこれを是正しないとき<br />
      (6) パートナーが自ら営業を停止したとき、又は監督庁より営業の取消、停止等の処分を受けたとき<br />
      (7) パートナーが支払停止若しくは支払不能の状態に陥ったとき、又は不渡処分を受けたとき<br />
      (8) パートナーに信用力の著しい低下があったとき、又は信用力に影響を及ぼす営業上の重要な変更があったとき<br />
      (9) パートナーが第三者より差押え、仮差押え、仮処分、その他強制執行若しくは競売の申立、又は公租公課の滞納処分を受けたとき<br />
      (10) パートナーに破産手続開始、会社更生手続開始、特別清算開始、民事再生手続の開始の申立その他類似の事実が生じたとき<br />
      (11) パートナーが解散又は他の会社との合併を決議したとき<br />
      (12) パートナーに災害、労働争議等により本規約又は個別契約の履行を困難にする事由が生じたとき<br />
      (13) パートナーにミスミに対する詐術その他本プラットフォームの利用を継続しがたい背信的行為が判明したとき<br />
      (14) パートナーの財産状態が悪化し、又はそのおそれがあると認められる客観的事由が生じたとき<br />
      (15) ミスミが相当の通信手段を尽くしてもパートナーとの連絡がとれないとき<br />
      (16) パートナーが第５条に基づき登録の取消しを行ったとき<br />
      (17)
      本プラットフォームから取得した情報を、権利者の事前の承諾を得ずに本プラットフォームで取り扱う機械部品の製造委託及び販売並びにこれらに関するサービスを提供すること以外の目的で使用した場合、又は、そのおそれがあることが合理的に疑われる場合<br />
      (18) 第３９条の定めに違反したとき<br />
      (19) その他本プラットフォームの利用者として不適格とミスミが認める事由が生じたとき<br />
      ２ パートナーが本規約に違反したことによりミスミが損害を被った場合には、パートナーは、かかる損害を賠償する責任を負うものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第５条 （自主的な登録取消し、利用の中断等）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーが本プラットフォームの利用登録の取消を希望する場合は、ミスミの定める方法により登録の取消を行うことができるものとします。ミスミは、当該届出を受けて、利用登録の取消を行うものとし、当該登録取消完了時点で、パートナーは本プラットフォームを利用することができなくなるものとします。ただし、既に成立している個別契約がある場合は、当該個別契約の履行完了まで本プラットフォームの全部又は一部を利用することができる場合があります。<br />
      ２ パートナーは、ミスミが別途定める方法により、本プラットフォームの利用を一時的に中断することができるものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第６条 （パートナーアカウント）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーは、１つのパートナーアカウントとそれに関連付けられた複数の個人アカウントを保有することができます。ミスミは、パートナーに対し、パートナーアカウント発行時に管理権限を有する一つの個人アカウントを発行します。<br />
      ２
      パートナーは、自らの責任のもと、アカウント（前項のパートナーのアカウントに関連付けられた全ての個人アカウントを含みます。以下同じ）を管理する義務を負います。パートナーは、パートナー又は第三者（パートナーの従業員、委託先及び代理人を含みます）による行為であるかどうかにかかわらず、パートナーのアカウントを利用して行われた全ての行為について責任を負います。<br />
      ３ パートナーは、アカウントの不正使用を認知した場合又はそれが疑われた場合は、直ちにミスミに連絡するものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第７条 （遵守事項）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、本プラットフォームの利用に際し、以下の事項を遵守することに同意します。<br />
      (1) 法令その他規則を遵守すること<br />
      (2) 個別契約の履行に必要な権利及び権限を有していること、並びに、第三者の権利を侵害しないこと<br />
      (3) ミスミに提供した情報が、最新かつ正確な情報となるようにすること<br />
    </div>
    <div class="text-h6 ma-5">
      第８条 （使用方法等及び製品保証に関する特則）
    </div>
    <div class="text-body-2 ma-10">
      １．パートナーは、本プラットフォームの利用に関し、本製品等の製造について以下の定めに従うものとします。パートナーは、顧客が以下に反した使用方法等を前提に製品等を発注したことを認識した場合、当該発注を承諾しない、又は認識した時点でキャンセルするものとします。なお、ミスミは当該不承諾及びキャンセルによりパートナー及び顧客に発生した費用及び損害を負担しません。<br />
      （１）ミスミ利用規約第１９条で禁止された使用方法等での使用はできません。ただし以下の場合を除きます。<br />
      ア） ミスミ利用規約第１９条（１）で定める人の運搬を目的とする自動車・車両機器・船舶等の輸送機器、人の治療・診察を目的とする医療機器、一般家庭で利用される電子・家電機器等の消費財への使用<br />
      イ） 同条（２）で定める使用用途のうち、航空宇宙機器及び原子力機器への使用<br />
      ウ）最終消費者へ提供する商品への組み込みその他これに類する用途<br />
      （２）前号に反しない限りで顧客とパートナー間の特段の合意がある場合、これに従います。<br />
      ２．ミスミは、顧客の使用方法等に関わらず、本製品等の使用によってパートナー、顧客又は第三者に生じるいかなる損害についても責任を負いません。<br />
      ３．顧客及びパートナー間で、製品の使用方法等について合意した場合、当該製品の保証内容も別途合意することができます。ただし、製品の保証内容につき、顧客及びパートナー間で何らの合意がない場合は、本規約の定めが適用されるものとします。
      <br />
      ４．前項の合意に関わらず、ミスミは、ミスミによる別段の定めがない限り、製品等の保証を行いません。 <br />
    </div>
    <div class="text-h6 ma-5">
      第９条 （禁止事項）
    </div>
    <div class="text-body-2 ma-10">
      パートナーが以下の各号に該当する行為を行うことを禁止します。<br />
      (1) 個別契約の成立後に、正当な理由なく個別契約の履行を拒絶すること<br />
      (2) 本プラットフォーム上で発行されるmeviy Marketplace型番（「meviy Marketplace型番」の定義は個別契約ルールで定めるとおりとします。）又は書面を本プラットフォーム外の取引で使用すること<br />
      (3) 本製品等、本ウェブサイト上の表記及び本プラットフォーム上の取引に関連して作成される書面等にミスミが製造者又は販売者と誤認されるおそれのある表示をすること<br />
      (4) ミスミが本プラットフォームを提供していないエリアの国又は地域に所在する顧客と本プラットフォームを利用して個別契約を締結すること<br />
      (5) ミスミ、本プラットフォーム及び本プラットフォーム利用者（顧客及び他の本プラットフォーム利用者を含みます。）の信用を毀損すること<br />
      (6) 他の本プラットフォーム利用者の業務を妨害すること<br />
      (7) 本プラットフォームの機能を利用し、他のパートナー、顧客又は第三者に対する誹謗中傷、名誉棄損又は権利侵害をすること<br />
      (8) 前各号のほか、本プラットフォームの利用方法として不適切な行為とミスミが認める行為、又は、そのおそれのある行為<br />
    </div>
    <div class="text-h6 ma-5">
      第１０条 （利用料及び支払方法）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーは、本プラットフォームの利用を開始した月から、以下の各号の利用料（以下、「利用料等」といいます。）その他費用をミスミに支払うものとします。なお、当該利用料等その他費用にかかる消費税その他の税金は、パートナーの負担とします。利用料等の詳細、支払期日及び支払方法は別途ルール等において定めるとおりとします。<br />
      (1) 本プラットフォーム利用料<br />
      (2) 販売手数料<br />
      (3) オプションサービス利用料<br />
      (4) その他ミスミとパートナー間で合意した費用<br />
      ２
      パートナーは、本プラットフォームを通じて行う顧客との本製品等の代金決済のため、DGFTとの間で、下記のURLで参照できる利用規約のうち、「パートナー利用マニュアル」記載のサービスに関する諸規約（以下、「DGFT決済サービス利用規約」といいます。）を主たる契約条件とする、DGFT収納代行サービスの利用にかかる契約（以下、「収納代行契約」といいます。）を締結しなければならないものとします。パートナーは当該利用契約へ同意したことをもって、DGFT決済サービス利用規約にも同意し、かつ、当該規約を主たる契約条件とする収納代行契約の締結に同意したものとみなします。<br />
      <div class="text-body-2 ma-2 ml-5">https://www.veritrans.co.jp/tos/contract.html<br /></div>
      ３ パートナーは前項の規定にかかわらず、DGFT決済サービス利用規約の規定のうちの一部を以下の通り修正することに合意します。<br />
      (1)
      第1条（用語の定義）第14号「MAP」に規定するMAPは、パートナーに対し提供されないものとします。よって、DGFT決済サービス利用規約内のMAPにかかる全ての規定はパートナーに対して適用されないものとします。<br />
      (2)
      第31条（本件決済サービス料金）第1項の規定にかかわらず、本件決済サービス料金は、ミスミがパートナーに対して請求する利用料等に含まれるものとします。このためDGFTは、DGFTがミスミに対し、本件決済サービス料金をDGFTに代わって代理受領する権限を付与していることを表明するものとします。これによりパートナーが本件決済サービス料金を含めた利用料等をミスミに支払った時点をもって、パートナーのDGFTに対する本件決済サービス料金支払債務は、前記利用料等に含まれる本件決済サービス料金の限度において履行されたものとします。<br />
      (3)
      パートナーは前号第1文のために、本規約への同意をもって、ミスミに対し、DGFTとの間でパートナーを代理して本件決済サービス料金について協議し、合意する代理権を付与するものとします。なおミスミは、代理権の構成にかかわらず、DGFTとの間で合意した本件決済サービス料金の詳細についてパートナーへ説明・報告する義務を負わないものとします。<br />
      (4)
      第32条（本件決済サービス料金及び商品代金の精算）第1項の規定にかかわらず、本件決済サービス料金の全てについて、「払込料金」として取り扱うものとします。ただし、本条第2項に基づく処理を前提に、DGFTからパートナーに対する本件決済サービス料金の個別の請求は行われないものとします。<br />
      (5)
      パートナーは、パートナーがミスミに対して利用料等の支払いを滞った場合、本項第2号から第4号までの規定にかかわらず、DGFTが直接パートナーに対し収納代行契約上のDGFTの権利を行使することにつき、予め承諾するものとします。<br />
      ４ パートナーはミスミに対し、以下に掲げる代理権限を、本規約同意の時点でミスミへ授与するものとします。<br />
      (1) パートナーを代理して、本件決済サービス料金を決定・同意、及びそれらにかかる意思表示を行う権限<br />
      (2)
      収納代行契約において、パートナーからDGFTに対し提供が求められる決済データを受領してDGFTに引渡す業務、及びDGFTがクレジットカード会社等の決済事業者（以下、同語句同義。）から受領した決済処理結果にかかるデータをDGFTから受領し、パートナーに引き渡す権限<br />
      (3) 収納代行契約におけるDGFT及び決済事業者の初期（契約時）審査又は契約後の途上審査においてDGFT又は決済事業者が求める資料及び情報をパートナーから受領し、DGFT又はDGFTを通じて決済事業者に引渡す権限<br />
      (4)
      収納代行契約又はパートナーと決済事業者との間に成立する加盟店契約に基づき、DGFT又は決済事業者が所定の調査等を行う場合に、DGFT又は決済事業者が求める資料及び情報をパートナーから受領し、DGFT又はDGFTを通じて決済事業者に引渡す権限<br />
      (5)
      その他、本項第1号から第4号までの事項を除き、収納代行契約締結後に当該契約に従い生じるDGFTとの一切の通信の授受及びその他の事務を履行し、ミスミがパートナーに代わって、当該契約にかかる全ての決定・同意及びそれらにかかる意思表示を行う権限<br />
      ５
      パートナーは、前項に基づく代理権をミスミに授与している期間中、DGFTに対する連絡等一切の行為は、ミスミに対して行うものとし、ミスミの許諾なくDGFTと直接やり取りを行わないものとします。ただし、本規約の規定又は収納代行契約に基づきDGFTが直接パートナーに照会・質問、その他の接触をした場合であって、パートナーが当該照会等に回答するために行うやり取りは除きます。<br />
      ６
      ミスミが、本プラットフォームを通じて行う顧客との本製品等の代金決済においてDGFTの提供する決済サービスを利用するために、ミスミとDGFTとの間で締結している契約が終了したこと等により、DGFTがＭＭサービス上の収納代行会社でなくなった場合、ミスミはパートナーに対し別途その旨を通知することにより、本規約においてDGFTが関与している部分は、適用除外されるものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第１１条 （顧客向け登録情報）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、パートナーが顧客に開示する目的で本プラットフォーム上に登録するパートナー自身及び本製品等に関する情報、別途ミスミが定める個別契約ルール「パートナー及び本製品等に関する情報の登録について」に従うものとします。
    </div>
    <div class="text-h6 ma-5">
      第１２条 （見積）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、見積に関する条件について、別途ミスミが定める個別契約ルールに従うものとします。
    </div>
    <div class="text-h6 ma-5">
      第１３条 （個別契約）
    </div>
    <div class="text-body-2 ma-10">
      １ 個別契約の条件は、契約当事者である顧客とパートナー間で定めることができるものとします。ただし、本規約及び本プラットフォームに関するその他の契約やルール等に違反した合意はできません。<br />
      ２ 個別契約について、別途ミスミが定める個別契約ルールに従うものとします。<br />
      ３
      パートナーは、顧客との間で個別契約以外の秘密保持契約等の契約（ただし、本プラットフォーム上での本製品等及びこれに関連する見積時の情報開示等のやり取りを対象とするものに限ります。）を、ミスミの指定する方法により、締結することができるものとします。ただし、本規約及びルール等に反する内容又はミスミに不利益を生じさせる内容の契約を締結することはできないものとします。<br />
      ４ パートナーは、前項に基づき顧客との間で契約を締結した場合、締結後速やかにミスミに当該契約内容を電子メール等ミスミが指定する方法で通知するものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第１４条 （個別契約の変更又はキャンセル）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナーは、個別契約成立後の変更又はキャンセルは、本ウェブサイト上で行う必要があります。<br />
      ２ 変更及びキャンセル条件については、別途ミスミが定める変更・キャンセルポリシー（変更・キャンセルルール）に従うものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第１５条 （委託料等）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーが顧客に提示する個別契約の委託料等には、以下の各号の費用が含まれるものとします。ただし、顧客、パートナー及びミスミの三者間で事前に文書により明確な合意がなされている場合、(1)以外の費用を顧客負担（着払等）とすることができるものとします。<br />
      (1) 本製品及び本役務の委託料又は売買代金<br />
      (2) 梱包費、配送料、保険料その他顧客への納入にかかる諸費用<br />
      (3) 見積回答に明記されている(1)(2)以外の費用<br />
      ２ 委託料等にかかる消費税等のその他税金は、顧客負担とします。<br />
    </div>
    <div class="text-h6 ma-5">
      第１６条 （出荷日）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナーは、見積時に出荷までに必要な日数を顧客に提示します。<br />
      ２
      パートナーは、顧客に対し、個別契約で提示した製品等を出荷する日（以下、「出荷日」といいます）に製品を出荷する必要があります。なお、個別契約成立時において、パートナーが出荷日を一定の期間で提示した場合は、個別契約成立後、速やかに当該期間内から出荷日を決定し、顧客に対して通知する必要があります。本条に定めるもののほか、詳細な出荷日の決定方法については、別段の定めによるものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第１７条 （梱包）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、法令及びミスミが別途定める要領に従い、本製品等を梱包して顧客に納入する必要があります。<br />
    </div>
    <div class="text-h6 ma-5">
      第１８条 （受領拒否）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、顧客が正当な理由なく本製品等の受領を拒否した場合、その情報を直ちにミスミに報告する必要があります。<br />
    </div>
    <div class="text-h6 ma-5">
      第１９条 （危険負担及び所有権）
    </div>
    <div class="text-body-2 ma-10">
      本製品等の危険負担及び所有権は、別段の定めがない限り、本製品等が納入場所に到着した時点でパートナーから顧客に移転します。<br />
    </div>
    <div class="text-h6 ma-5">
      第２０条 （検査）
    </div>
    <div class="text-body-2 ma-10">
      本製品等の検査期間は、特段の合意がない限り、顧客が本製品等を受領した日から一週間とします。この期間内に、顧客からパートナーへの検査合格の通知がない場合は、本製品等はこの期間の満了時点をもって顧客の当該検査に合格したものとみなします。<br />
    </div>
    <div class="text-h6 ma-5">
      第２１条 （不合格品があった場合の措置）
    </div>
    <div class="text-body-2 ma-10">
      前条の検査により、本製品等の全部又は一部が、顧客の検査に不合格になった場合、顧客とパートナーは、個別契約の定めに従い、必要な措置をとるものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第２２条 （支払）
    </div>
    <div class="text-body-2 ma-10">
      １ 顧客による委託料等の支払方法は、別段の定めがない限り、ミスミ利用規約第22条の定めに従い顧客が選択した支払方法によるものとします。<br />
      ２
      顧客が銀行振込による支払方法を選択した場合、顧客のパートナーに対する委託料等金銭支払い債務に関し、パートナーが本製品等を出荷した時点をもって、ミスミが顧客に別途許容した利用限度額の範囲内で当該債務を免責的に引き受けるものとします。ただし、顧客が銀行振込による支払方法を選択した場合であっても、ミスミとの本プラットフォームを含む全ての取引における過去の支払い状況及び未払い債務の総額等の事情を総合的に勘案し、注文確定後に委託料等のミスミへの支払方法が先払い又はクレジットカード決済に変更される場合があることを承諾するものとします。この場合、ミスミが別途指定する期日までに当該委託料等の支払いが確認できないとき又はクレジットカードの利用がクレジットカード会社より認められなかったとき、ミスミは対象の個別契約をキャンセル又は出荷日の変更等ミスミ所定の対応を行うことができるものとします。当該キャンセル又は変更等によりパートナーに生じた費用（但し、実費分に限ります。）はミスミが負担するものとします。<br />
      ３
      前項に関し、注文確定後に委託料等の金額変更があった場合、本製品等を出荷した後に、当該変更により生じた差分に関し、増加分はミスミが当該分（ただし、ミスミが顧客に別途許容した利用限度額の範囲に限り、当該範囲を超える分については別途ミスミの指定する方法により精算するものとします。）を債務引受けし、減少分は当該分の引き受けた債務の消滅が生じるものとします。また、個別契約がキャンセルされた場合、前項でミスミが引き受けた金銭支払い債務は当該個別契約の成立時点に遡って消滅するものとします。ただし、有償によるキャンセルについては、顧客の当該有償分の支払い債務（ただし、ミスミが顧客に別途許容した利用限度額の範囲に限り、当該範囲を超える分については別途ミスミの指定する方法により精算するものとします）につき、ミスミが免責的に引き受けるものとします。なお、個別契約の変更又はキャンセルによる各金銭債務及び金銭債権の精算方法については、ミスミが別途定める方法に従うものとします。<br />
      ４
      顧客がクレジットカード決済を選択又は現金による先払いをミスミより指定された場合、顧客のパートナーに対する委託料等の金銭支払い債務に関し、ミスミがパートナーに代わって顧客、クレジットカード会社又はDGFTから代理受領するものとします。また、顧客、クレジットカード会社又はDGFTが委託料等を支払わなかったにもかかわらず、ミスミがパートナーに対し当該金額分の支払いを先履行として行っていた場合、パートナーはミスミに対し当該金額分の返金を行うものとし、その精算方法についてはミスミが別途定める方法に従うものとします。<br />
      ５
      前項に関し、注文確定後に委託料等の金額変更があった場合、本製品等を出荷した後に、当該変更により生じた差分に関し、増加分はミスミが当該分を代理受領し、減少分は当該分の代理受領の消滅（委託料等受領後は顧客への返金）が生じるものとします。また、個別契約がキャンセルされた場合、前項の金銭支払い債務は個別契約の成立時点に遡って消滅するものとし、これによりミスミの代理受領も消滅します。ただし、有償によるキャンセルについては、顧客の当該有償分の支払い債務につき、ミスミが顧客、クレジットカード会社又はDGFTから代理受領するものとします。なお、個別契約の変更又はキャンセルによる各金銭債務及び金銭債権の精算方法については、ミスミが別途定める方法に従うものとします。<br />
      ６ 本条各項に関する条件は、顧客、クレジットカード会社又はDGFT及びパートナーの合意があっても変更することはできません。<br />
      ７
      パートナーは、顧客、クレジットカード会社又はDGFTから直接委託料等を受領することができないものとします。本項に違反して直接委託料等を受領した場合、パートナーは当該受領を知った時点で速やかにミスミに連絡するものとし、ミスミのパートナーに対する当該委託料等の支払い義務は個別契約の成立時点に遡って消滅すること及び既にミスミから当該委託料等の支払いを受領している場合はミスミの指示に従い返金又は他の金銭債権との相殺を行うことに合意するものとします。<br />
      ８ パートナーは、前項の委託料等の直接受領につき故意又は重大な過失が認められる場合、ミスミに対し、委託料等相当額を違約金として支払います。<br />
      ９ パートナーは、本条による債務の引受け又は代理受領が行われたときは、当該個別契約及びその債務の引受け又は代理受領にかかる無効、取消、解除その他一切の抗弁をミスミに対して主張することができないものとします。<br />
      １０ ミスミは、本サービス上発生したものか否かにかかわらず、ミスミがパートナーに対して生じた一切の債務又は債権について相殺による差引計算し、残額を得ること又は合算して支払いをすることができるものとします。<br />
      １１ ミスミは、別途定める支払方法に従い、顧客、クレジットカード会社又はDGFTより受領した委託料等及びそれにかかる税金等をパートナーに支払います。<br />
      １２
      パートナーは、顧客のアカウントが第三者に不正利用された事実又はそのおそれが認められる場合及び顧客が第三者のクレジットカード番号を不正に利用した事実又はそのおそれが認められる場合、速やかにミスミにその旨を通知するものとします。<br />
      １３
      ミスミは、顧客のアカウントが第三者に不正利用され当該不正利用に基づき個別契約が成立した場合、当該不正利用についてパートナーに故意又は過失が認められない限り、当該個別契約による委託料等を本条各項に基づきパートナーに支払うものとします。なお、パートナーが前項の通知を怠った場合、当該不正利用につき故意又は過失が認められるものとします。<br />
      １４
      ミスミ及びパートナーは、顧客が第三者のクレジットカード番号を不正に利用し当該不正利用に基づき個別契約が成立した場合、当該個別契約に基づくパートナーへの委託料等の支払いはカード会社及びDGFTの判断に従うものとします。ただし、パートナーに委託料等が支払われないと判断された場合、かつ当該不正利用に関しパートナーに故意又は過失が認められない場合は、当該委託料等相当額をパートナーはミスミに請求できるものとします。なお、パートナーが本条第13項の通知を怠った場合、当該不正利用につき故意又は過失が認められるものとします。<br />
      １５ 本条第13項と前項が重複する場合（顧客のアカウント不正利用かつ顧客のクレジットカード番号の不正利用がなされた場合）、前項のみが適用されるものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第２３条 （顧客の利用限度額）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーは、顧客による本プラットフォーム上での本製品等の購入及びＭＭサービス利用の限度額をミスミがミスミ利用規約第８条の定めに従い定めることができること、及び顧客がクレジットカードによる決済を選択した場合、当該限度額はカード会社の顧客に対する与信残高等に基づくことを承諾します。<br />
      ２ パートナーは、個別契約の委託料等の総額が前項で定める顧客の利用限度額を超過する場合は、個別契約の成立時期が通常より時間を要する場合があること、又は、個別契約が成立しない場合があることを予め承諾します。<br />
    </div>
    <div class="text-h6 ma-5">
      第２４条 （パートナーの取引限度額）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、パートナーが本プラットフォーム上で受託する取引金額の総額が一定の金額を超える場合又は超えるおそれがある場合、ミスミの判断により、次条に定める補償の免除を含む本規約とは異なる条件が適用される場合があることを承諾します。<br />
    </div>
    <div class="text-h6 ma-5">
      第２５条 （未払時の補償）
    </div>
    <div class="text-body-2 ma-10">
      １
      ミスミは、パートナーに責めに帰すべき事由がない（本製品等の製作、梱包、出荷及び配送の手配に不備がなかったこと等をいいます）にもかかわらず（但し、顧客がクレジットカード決済を選択した場合にはクレジットカード会社の信用照会を通過したことを条件とします。）、顧客、クレジットカード会社又はDGFTから委託料等又はキャンセル料の支払いがなかった場合、当該委託料等相当額をパートナーに支払います。ただし、ミスミとパートナー間で別段の定めがある場合は、その定めに従います。<br />
      ２
      パートナーは、ミスミがパートナーに前項に定める支払をすることを条件に、パートナーが顧客に対して有する当該個別契約の代金債権及びクレジットカード会社又はDGFTに対して有する委託料等相当額の金銭債権をミスミに譲渡します。<br />
      ３ パートナーが顧客から直接委託料等を受領し、第２２条第７項に基づく返金等のミスミの指示に従わない場合には、本条第１項は適用されません。<br />
    </div>
    <div class="text-h6 ma-5">
      第２６条 （品質）
    </div>
    <div class="text-body-2 ma-10">
      本製品等の品質に対する責任は、個別契約の定めに従い、パートナーが負うものとします。
    </div>
    <div class="text-h6 ma-5">
      第２７条 （契約不適合責任）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーは、顧客に対し、本製品等の契約不適合責任を負います。契約不適合責任の内容の詳細は、顧客及びパートナー間の合意により定めることができるものとします。ただし、当該合意により本条各項で定める事項を変更することはできないものとします。<br />
      ２
      契約不適合等パートナーの責に帰すべき事由による個別契約の解除により、顧客が支払済みの金銭の返金を求めた場合は、以下のとおりとします。ただし、パートナーは、当該個別契約の解除により顧客に返金した場合であっても、ミスミに対して所定の利用料等を支払う義務を負うものとします。<br />
      (1) ミスミが委託料等をパートナーに対して未払いの場合<br />
      パートナーは、ミスミに対し、顧客（クレジットカード決済の場合はDGFTとする。本項第2号の「顧客」についても同じ。）への返金を指示し、ミスミは当該指示に従い返金します。<br />
      (2) ミスミが委託料等をパートナーに対して支払済みの場合<br />
      パートナーは、ミスミに顧客への返金分の金銭を預託し、ミスミから顧客へ返金します。<br />
      ３
      パートナーは、契約不適合責任に基づく個別契約の変更又はキャンセルについて、変更・キャンセルポリシー（変更・キャンセルルール）にて定める「パートナー都合の変更」又は「パートナー都合のキャンセル」に該当するものとし、当該事項に定める内容に従うものとします。<br />
      ４ パートナーは、顧客との間に特段の合意がない場合は、本製品等を出荷した日から１年間本条に定める責任を負うものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第２８条 （製造物責任）
    </div>
    <div class="text-body-2 ma-10">
      本製品等の製造物責任は、パートナーが負うものとします。パートナーは、製造物責任の発生を予防し、その責任を最小限に止めるために必要な資料を自ら保持するものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第２９条 （アップロードデータの取扱い）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナーが本ウェブサイトにアップロードしたCADデータその他個別契約に関するデータ等は、ミスミが管理するサーバー（以下、本サーバーといいます）に保管されます。当該データ等は、アップロードが正常に完了した時点でアップロードデータとなります。<br />
      ２ ミスミは、第３４条に定める義務を除き、アップロードデータについて一切責任を負いません。また、ミスミは、ミスミの裁量によりアップロードデータを本サーバーから削除する場合がございますので、あらかじめご了承下さい。<br />
      ３ ミスミが前項に基づきアップロードデータを本サーバーから削除する場合、ミスミはパートナーに対し、事前にその旨を通知するか又は相当な方法で周知した上で削除します。<br />
      ４
      アップロードデータが第７条又は第９条の定めに違反する場合、又はそのおそれがある場合、パートナーは本ウェブサイトをご利用いただくことはできません。この場合、ミスミは裁量によりパートナーへの予告なくアップロードデータを本サーバーから削除する場合がございます。ミスミは、それらに関連して顧客、パートナー又は第三者に生じるいかなる損害についても何ら責任を負いません。また、パートナーのアップロードデータにより顧客又はミスミが損害を被った場合、パートナーはかかる損害を賠償する責任を負うものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第３０条 （知的財産権等の取扱い）
    </div>
    <div class="text-body-2 ma-10">
      １
      本プラットフォームに関する発明、考案、意匠、商標、著作物（ＣＡＤデータ、その他の図面及び最終仕様書等をいいますが、これらに限りません）その他の知的財産（ノウハウ及び技術情報を含みます。）及び特許権、実用新案権、意匠権、商標権、著作権その他の知的財産権等（特許権、実用新案権、意匠権及び商標権を取得するための権利並びに著作権法第２７条及び第２８条に定める権利を含みます。知的財産及び知的財産権を総称して「知的財産権等」といいます。以下同じ）は、ミスミに帰属します。<br />
      ２ 本ウェブサイト上に提供された各種情報に関する知的財産権等は、本ウェブサイト上に提供する以前よりその権利を有している権利者に留保されます。<br />
      ３ パートナーは、本ウェブサイト上に各種情報を提供する場合は、自らが前項に定める権利を有するか、又は、各種情報を本ウェブサイト上で利用するために必要な権利を有することを保証します。<br />
      ４
      本プラットフォーム上で顧客とパートナーの共同作業によって新たに創出された知的財産権等は、個別契約において別段の定めがない限り、顧客とパートナーが共有するものとします。この場合、顧客及びパートナーは、当該知的財産権等をそれぞれ自ら単独で利用することができます。また、ミスミは、個別契約の履行の支援及び本プラットフォームの利便性向上の目的で、顧客とパートナーがそれぞれ有する知的財産権等（顧客とパートナー間で共有する知的財産権等を含みます。）を利用することができるものとします。顧客及びパートナーは、顧客とパートナーが共有する当該知的財産権等をミスミ以外の第三者に開示又は利用させる場合は、相手方の事前の承諾を得なければならないものとします。<br />
      ５
      前項の定めに関わらず、顧客及びパートナーが、当該知的財産権等をいずれか一方に帰属させることに合意した場合は、いずれか一方が当該権利を有するものとします。なお、顧客及びパートナーは、知的財産権等がいずれか一方に権利が帰属する場合であっても、当該権利を前項但書に規定する目的の範囲内でミスミが利用することを妨げません。<br />
    </div>
    <div class="text-h6 ma-5">
      第３１条 （商標）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナー及びミスミは、ルール等及び本ウェブサイト上で別途定めるところに従い、相手方の商標を、本ウェブサイト上又は本プラットフォームのプロモーションを行う媒体で使用することができます。ただし、相手方の承諾なく、第三者に使用を再許諾することはできません。<br />
      ２ 前項の定めに関わらず、パートナーは、ミスミが本製品等の製造者、販売者又は輸出入者と誤認されるような表示をすることはできません。<br />
      ３ 本条第１項の定めに関わらず、パートナー及びミスミは、相手方から商標の使用を中止するよう通知された場合は、遅滞なくその使用を中止します。<br />
    </div>
    <div class="text-h6 ma-5">
      第３２条 （再委託）
    </div>
    <div class="text-body-2 ma-10">
      １
      ミスミは、本規約及び個別契約に関する業務の全部又は一部を第三者（以下、ミスミの再委託先といいます）に委託することができます。ミスミは、ミスミの再委託先へ業務を委託する場合、再委託先の業務の履行について、顧客又はパートナーに帰責事由がある場合を除き、自ら業務を遂行した場合と同様の責任を負うものとします。<br />
      ２
      パートナーは、別段の定めのない限り、本規約及び個別契約に関する業務の全部又は一部を第三者（以下、パートナーの再委託先といいます）に委託することができます。パートナーは、パートナーの再委託先へ業務を委託する場合、再委託先の業務の履行について、顧客又はミスミに帰責事由がある場合を除き、自ら業務を遂行した場合と同様の責任を負うものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第３３条 （保険）
    </div>
    <div class="text-body-2 ma-10">
      パートナーは、ミスミからの要求があった場合は、パートナーの費用負担において、本ウェブサイト上で成立した契約により生じる責任を担保するための保険に加入しなければならない場合があります。<br />
    </div>
    <div class="text-h6 ma-5">
      第３４条 （秘密保持）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナー、ミスミ及び顧客は、情報を開示した者（以下、情報開示者といいます。）の事前の書面による承諾がない限り、本規約（ルール等を含みます。以下本条において同じ）及び個別契約の内容、並びに本規約及び個別契約に定める取引（見積に関するやり取りを含みます。）を履行する上で知り得た情報開示者の知識、ノウハウ、情報等（個別契約の仕様等を含みますが、これに限定されません。以下、「秘密情報」といいます。）を本規約及び個別契約の取引の履行以外の目的で使用してはならず、以下のイロに定めるミスミの関連会社、又はDGFT（ただし、収納代行契約履行のために必要な場合であって、かつ、左記のために必要な情報に限ります。）以外の第三者（本件業務に関係する役職員、法律上の守秘義務が課せられた弁護士、弁理士、公認会計士、税理士等を除きます。）に開示又は漏洩してはならないものとします。ただし、個別契約に関する秘密情報に限り、パートナー、ミスミ及び顧客は、当該秘密情報をそれぞれに対し開示できるものとします。<br />
      <div class="d-inline-block">
        <div class="d-inline-block">
          ミスミの関連会社<br /><br />
        </div>
        <div class="d-inline-block">
          イ 親会社（会社法第２条第４号に規定するものをいいます。以下同じ。）<br />
          ロ 親会社の子会社（会社法第２条第３号に規定するものをいいます。以下同じ。）
        </div>
      </div><br />
      ２ パートナー、ミスミ及び顧客は、下記の事項に関しては、前項の義務を負いません。<br />
      (1) 公知である情報<br />
      (2) 情報開示者から明らかにされる前に既に正当に保持していた情報<br />
      (3) 守秘義務を負わない第三者より正当に受領した情報<br />
      (4) 自らの責に帰すべき事由によらず、受領後、公知となった情報<br />
      (5) 情報開示者の秘密情報を参照することなく、自ら開発したことが証明できる情報<br />
      (6) 法令等に基づき開示が要求されたもの<br />
      ３ パートナーは、顧客及びミスミの秘密情報を、情報開示者の事前の承諾なく、パートナーの製品又はサービスの提供等の営業行為（直接又は第三者を通じて行うかを問いません。）に利用することはできません。<br />
      ４
      本条第１項ないし第３項の定めに関わらず、パートナー、ミスミ及び顧客は、情報開示者から受領した秘密情報を、次の各号に定める第三者に開示し、又は、使用させることができるものとします。ただし、当該第三者が第３５条第１項と同等の義務を負うことを承諾した場合に限ります。<br />
      (1) 個別契約の履行（見積を含みます。）の目的で、個別契約を履行するための業務を委託する再委託先に開示又は使用する場合<br />
      (2) 個別契約により製作された本製品等を使用する目的で、本製品等の使用者に開示又は使用する場合<br />
      (3) 個別契約により製作された本製品等を再度パートナーに本ウェブサイトを通じて発注する目的で、本製品等の使用者に開示又は使用する場合<br />
      ５
      本条第１項及び次条の定めにかかわらず、パートナーは、ミスミが公的機関その他ミスミが合理的であると判断する第三者からＭＭサービスの提供を通じてミスミが取得した情報（個人情報を含みます。）の提供を求められた場合、商慣習上合理的な範囲においてその情報を提供することに同意します。ただし、ミスミは、自己の裁量で、事前に、又は事後に、情報提供の事実をパートナーに通知いたします。<br />
      ６ 
      パートナーは、本プラットフォーム上にパートナーが提供した各種情報を、ミスミが本プラットフォームの各種機能の向上及びＭＭサービスのプロモーションを目的として、第１項を遵守することを前提として非独占的に利用（複製、翻案又は改変して利用することを含みます。）することを許諾するものとします。ただし、パートナーが別途利用を制限する旨の通知（ただし、ミスミが指定する方法に限るものとします。）をミスミに行った場合、ミスミはその内容に従うものとします。<br />
      ７
      パートナー及びミスミは、本プラットフォームを利用して製作された本製品等の製作事例を公開又は利用する場合には、自社以外の秘密情報の開示とならないよう十分注意するものとし、当該公開又は利用が、顧客の秘密情報の公開となるおそれがある場合は、直ちにその製作事例の公開又は利用を中止します。<br />
      ８
      パートナーは、顧客の秘密情報に関し本条第1項乃至第4項及び第７項に違反した場合、当該違反について顧客に対し直接責任を負うものとします。また、顧客が本条第１項乃至第４項及び第７項に違反した場合、当該違反についてミスミを介さず直接顧客に責任追及するものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第３５条 （個人情報・顧客情報）
    </div>
    <div class="text-body-2 ma-10">
      １
      パートナー及びミスミは、本規約及び個別契約の履行の過程で知り得た顧客の個人情報及び顧客情報を「ミスミグループ個人情報・お客様情報保護方針」（https://jp.misumi-ec.com/contents/privacy/）の定めに従い、取り扱うものとします。<br />
      ２ パートナーは、個人情報及び顧客情報の管理責任者の選任及びその公表等、「ミスミグループ個人情報・お客様情報保護方針」に定める個人情報の共同利用のために必要な措置を行います。<br />
      ３ パートナー及びミスミは、自己が管理する個人情報及び顧客情報が漏洩することがないよう適切な管理を行わなければなりません。<br />
    </div>
    <div class="text-h6 ma-5">
      第３６条 （不可抗力）
    </div>
    <div class="text-body-2 ma-10">
      天災地変等の不可抗力、戦争、暴動、内乱、法令の改廃制定、公権力による命令処分、労働争議、輸送機関の事故、その他顧客及びミスミの責に帰し得ない事由による契約の全部又は一部の履行遅滞、履行不能又は不完全履行が生じた場合には、顧客及びミスミはこれにより生じた責任を負わないものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第３７条 （権利義務の譲渡）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナー及びミスミは、別段の定めがない限り、本規約又は個別契約により生じる権利義務の全部又は一部を第三者へ譲渡し、又は担保に供してはならないものとします。<br />
      ２
      パートナーは、個別契約の委託料等の債権の譲渡等が法的に有効になされた場合であっても、ミスミの合意がない場合は、ミスミに対し、その事実を主張できないものとし、第２２条の定めに準じて委託料等相当額をミスミに支払わなければならないものとします。（第２５条第２項を除きます。）。パートナーがこれに反し、ミスミ以外の第三者へ債権譲渡等を行った場合、第２５条第１項は適用されません。<br />
    </div>
    <div class="text-h6 ma-5">
      第３８条 （終了）
    </div>
    <div class="text-body-2 ma-10">
      ミスミは、パートナーに対する書面による２か月前の予告をもって本規約の適用を終了することができます。<br />
    </div>
    <div class="text-h6 ma-5">
      第３９条 （反社会的勢力）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナー、ミスミ及び顧客は、自己以外の相手方に対し、以下の事項を遵守することに同意します。<br />
      (1) 自己又は自己の経営陣が暴力団、暴力団員又はこれらに準ずる者（以下、「反社会的勢力」といいます。)でないこと及び反社会的勢力でなかったこと<br />
      (2) 反社会的勢力を名乗り又は反社会的勢力を利用して、相手方若しくは第三者の名誉、信用を毀損し、業務の妨害を行い又は不当要求行為をなさないこと<br />
      (3) 反社会的勢力に対し資金提供をする等の関与を行わないこと<br />
      ２ パートナー、ミスミ及び顧客は、自己の取引先にも前項の規定を遵守させることに努めるものとします。<br />
      ３ パートナー、ミスミ及び顧客は、前2項に違反した場合、当該違反について自己以外の相手方に対しそれぞれ直接責任を負うものとし、当該違反についてミスミを介さず直接他の相手方に責任追及するものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第４０条 （本プラットフォーム等の変更及び中断）
    </div>
    <div class="text-body-2 ma-10">
      １
      ミスミは、開発上又はその他の理由により、本プラットフォームの一部又は全部を変更、中断又は終了（以下、変更等といいます）する場合があります。その場合、ミスミは、パートナーに対し、事前にその内容を通知します。ただし、ミスミが合理的な理由により軽微な変更と判断した場合、又は、急を要する事由があると判断した場合については、事前の通知なくこれらの変更等をする場合があります。<br />
      ２ 前項の定めによる変更等があった場合でも、ミスミは、パートナーに生じた損害を賠償する責任を負いません。ただし、ミスミに重過失又は故意があった場合はこの限りではありません。<br />
    </div>
    <div class="text-h6 ma-5">
      第４１条 （期限の利益の喪失）
    </div>
    <div class="text-body-2 ma-10">
      パートナーが、第４条登録取消事由の一に該当した場合、パートナーは、ミスミに対する全ての債務について、当然に期限の利益を失い、直ちにミスミに対し債務全額を現金にて支払わなければならないものとします。この場合、ミスミは別途パートナーに通知することなく、ミスミがパートナーに対して有する債権とパートナーがミスミに対して有する債権とを対当額にて相殺し、パートナーのミスミに対する債務の弁済に充当します。<br />
    </div>
    <div class="text-h6 ma-5">
      第４２条 （利用終了後の措置）
    </div>
    <div class="text-body-2 ma-10">
      １ パートナーは、登録取消、ミスミによる本プラットフォームの提供の終了等の事由の如何に関わらず、本プラットフォームの利用を終了した場合、次の各号に定める措置を実施します。<br />
      (1) ミスミの所有物を保有する場合は、直ちにこれをミスミに返還し、ミスミからライセンスされた権利がある場合は、当該ライセンスの利用を速やかに終了するものとします。<br />
      (2) ミスミ又は顧客が提供した電磁的記録を保有する場合は、直ちにこれを提供者の指示に従い消去又は廃棄する。なお、消去又は廃棄が完了した場合、パートナーは、提供者に対して直ちにその旨を文書で報告するものとします。<br />
      (3) 本プラットフォームの利用終了時に有効な個別契約がある場合は、本規約の定めに従い当該個別契約の履行を完了するものとします。<br />
      ２ パートナーは、本プラットフォームの利用が終了した場合においても、以下の義務を負います。ただし、第３４条の義務は、本プラットフォームの利用が終了した時から５年を経過することにより消滅するものとします。<br />
      (1) 第４条（登録の取消、解除等）第２項<br />
      (2) 第５条（自主的退会）<br />
      (3) 第６条（パートナーアカウント）<br />
      (4) 第８条（使用目的及び製品保証に関する特則）<br />
      (5) 第９条（禁止事項）<br />
      (6) 第２１条（不合格品があった場合の措置）<br />
      (7) 第２６条（品質）<br />
      (8) 第２７条（契約不適合責任）<br />
      (9) 第２８条（製造物責任）<br />
      (10) 第２９条（アップロードデータの取り扱い）<br />
      (11) 第３０条（知的財産の取扱い）<br />
      (12) 第３４条（秘密保持）<br />
      (13) 第３５条（個人情報・顧客情報）<br />
      (14) 第３６条（不可抗力）<br />
      (15) 第３７条（権利義務の譲渡）<br />
      (16) 第４１条（期限の利益の喪失）<br />
      (17) 第４２条（利用終了後の措置）<br />
      (18) 第４３条（損害賠償請求）<br />
      (19) 第４５条（免責）<br />
      (20) 第４７条（条項一部無効又は合意対象除外時の取扱い）<br />
      (21) 第４８条（管轄裁判所）<br />
    </div>
    <div class="text-h6 ma-5">
      第４３条 （損害賠償）
    </div>
    <div class="text-body-2 ma-10">
      パートナー及びミスミは、相手方が本規約に違反したとき、又は、パートナーが個別契約に違反したとき、その他相手方の行為により損害を被ったときは、相手方に対してその賠償を請求することができます。<br />
    </div>
    <div class="text-h6 ma-5">
      第４４条 （通知）
    </div>
    <div class="text-body-2 ma-10">
      本利用規約上に定める、ミスミからパートナーへの通知は、（１）パートナーの本店所在地又はパートナーが自ら本ウェブサイト上に登録した住所地に対し、発送したことを証明できる方法による配送、（２）パートナーが自ら本ウェブサイト上に登録したメールアドレス宛に電子メールの送付又は（３）本ウェブサイト上での連絡通知のいずれかにより行われるものとします。
    </div>
    <div class="text-h6 ma-5">
      第４５条 （免責）
    </div>
    <div class="text-body-2 ma-10">
      １
      ミスミは、本ウェブサイトの利用による、コンピュータ等利用機器の障害及び故障、第三者の悪意に基づく当社サーバーへのハッキング、改ざん、不正なデータ取得等により、営業の中断、営業及び商品情報の喪失・漏洩等パートナーに生じた損害並びに第三者からの請求に基づくパートナーの損害について、原因の如何を問わず、一切の責任を負いません。通信回線障害、天災、事故等による作業延滞及びデータ喪失等に起因する損害についても、ミスミは一切の責任を負いません。ただし、これら事象の発生したことを当社が知り得た場合には当該事情に応じた合理的期間内に、パートナーに通知致します。<br />
      ２
      ミスミは、本ウェブサイト及びＭＭサービス上で提供されるサービス、サーバー又はミスミから提供された電子的通知（電子メール及び電子ファイルを含むが、これらに限られない。）が、ウイルス又はその他の有害な要素に感染していないことを保証いたしません。<br />
      ３ パートナーが本規約及び個別契約の定めに違反して生じた損害については、ミスミは本規約に定めるもののほかいかなる責任も負いません。<br />
      ４
      ＭＭサービスの提供に伴いパートナーに開示する顧客に関する情報について、当該情報は顧客が自身の責任において登録したものであり、ミスミはその内容の正確性について一切保証しないものとし、また当該情報を信用したことでパートナーに損害が生じたとしても一切責任を負わないものとします。<br />
      ５ パートナーは、本ウェブサイト上で掲載、登録、アップロードする自身に関する情報及び顧客に発信する見積回答等の情報について、第三者の知的財産や情報を使用する場合、当該第三者との間で必要な許諾を得るものとします。<br />
      ６
      パートナーは、本プラットフォーム内か否かにかかわらず、顧客又は第三者との間で何らかの紛争が発生した場合には、パートナーが顧客又は当該第三者との間で責任をもって解決するものとし、ミスミに一切の責任を負わせないものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第４６条 （規約の改正等）
    </div>
    <div class="text-body-2 ma-10">
      １
      ミスミは、本規約の改定、変更及び修正（以下、「改定等」といいます）を行うことができるものとします。ミスミは、本規約の改定等にあたり、改定等の効力発生日の前までに、改定等の内容及び効力発生日その他必要な事項を本ウェブサイトに掲示する方法その他相当な方法で周知します。<br />
      ２ 本規約の改定等は、周知の際に定める効力発生日から適用されるものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第４７条 （条項一部無効または合意対象除外時の取扱い）
    </div>
    <div class="text-body-2 ma-10">
      パートナー及びミスミは、本規約のいずれかの定めが法令等に基づいて無効又は同意対象から除外される場合には、当該定めは法令の遵守に必要な範囲でのみ、修正又は削除されたものとみなします。ただし、本規約のその他の定めは引き続き有効に存続するものとします。<br />
    </div>
    <div class="text-h6 ma-5">
      第４８条 （管轄裁判所）
    </div>
    <div class="text-body-2 ma-10">
      本規約に関連して生じた紛争については、紛争当事者間の別段の定めがない限りは、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br />
    </div>
    <div class="text-h6 ma-5">
      第４９条 （協議事項）
    </div>
    <div class="text-body-2 ma-10">
      本規約に疑義のある事項又は定めのない事項については、パートナー及びミスミ間で協議の上これを解決します。<br />
    </div>
    <div class="text-h6 ma-5">
      第５０条 （特例）
    </div>
    <div class="text-body-2 ma-10">
      ミスミは、ミスミ所定の方法で指定したパートナー及び顧客に対し、本規約又はルール等で本プラットフォーム又は本ウェブサイト上で行うことと定めた操作又は対応について、電子メール等ミスミが指定する方法で代わりに行うことができるものとします。ただし、当該対応は例外措置であり、ミスミが当該対応を終了する旨の通知を行った場合、当該パートナー又は顧客は当該対応を速やかに終了するものとします。<br />
    </div>

    <div class="text-body-2 ma-10" align="right">
      以上<br />
      制定日：2021年8月12日<br />
      改定日：2024年9月20日<br />
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'TermsOfUse',
}
</script>