/* eslint-disable no-console */
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import PartnerForm from "@/forms/PartnerForm";
import store from "@/store";
import axios from 'axios'

export default {

  async uploadAttachment (file) {
    // PreSignedURL取得
    const getAttachmentUploladPresignedUrl = `query getAttachmentUploladPresignedUrl($name:String, $type:String) {
      getAttachmentUploladPresignedUrl(name:$name, type:$type) {
            id
            name
            url
          }
        }`
    const params = {
      name: file.name,
      type: file.type
    }
    const presignedurl = await API.graphql(graphqlOperation(getAttachmentUploladPresignedUrl, params))
        .catch(e => console.log(e.message))
    const file_url = presignedurl.data.getAttachmentUploladPresignedUrl.url
    const file_id = presignedurl.data.getAttachmentUploladPresignedUrl.id

    var reader = new FileReader()
    reader.readAsArrayBuffer(file)

    await axios.put(
        file_url,
        file,
        {
          headers: {
            'Content-Type': file.type,
          },
        }
    ).catch(e => console.log(e.message))
    return file_id
  },
  async registerPartnerCandidate (form) {

    let paramBankAttachment = [];
    const files = form.bank_attachment
    for(let v of files) {
      const fileid = await this.uploadAttachment(v)
      let fileparam = {"id":fileid, "name":v.name}
      paramBankAttachment.push(fileparam)
    }

    const createPartnerCandidateQuery = `
          mutation createPartnerCandidate(
            $regist_user_cognito_sub: String!,
            $regist_user_name: String!,
            $regist_user_email: String!,
            $company_name_kanji: String,
            $company_name_kana: String,
            $zip_cd: String,
            $address_1_kanji: String,
            $address_2_kanji: String,
            $address_1_kana: String,
            $address_2_kana: String,
            $tel: String,
            $fax: String,
            $invoice_no: String,
            $representative_name: String,
            $representative_title: String,
            $person_name_sei_kanji: String,
            $person_name_mei_kanji: String,
            $person_name_sei_kana: String,
            $person_name_mei_kana: String,
            $buka_name_kanji: String,
            $buka_name_kana: String,
            $person_tel: String,
            $person_fax: String,
            $person_email: String,
            $bank_code: String,
            $branch_code: String,
            $bank_name: String,
            $branch_name: String,
            $account_type: String,
            $account_number: String,
            $account_name: String,
            $bank_attachment: [Attachment],
            $comission_person_name_sei_kanji: String,
            $comission_person_name_mei_kanji: String,
            $comission_person_name_sei_kana: String,
            $comission_person_name_mei_kana: String,
            $comission_person_tel: String,
            $comission_person_email: String,
            $agreement_id: [String],
            $status: String,
          ) {
              createPartnerCandidate(
                regist_user_cognito_sub:$regist_user_cognito_sub,
                regist_user_name:$regist_user_name,
                regist_user_email:$regist_user_email,
                company_name_kanji:$company_name_kanji,
                company_name_kana:$company_name_kana,
                zip_cd:$zip_cd,
                address_1_kanji:$address_1_kanji,
                address_2_kanji:$address_2_kanji,
                address_1_kana:$address_1_kana,
                address_2_kana:$address_2_kana,
                tel:$tel,
                fax:$fax,
                invoice_no:$invoice_no,
                representative_name:$representative_name,
                representative_title:$representative_title,
                person_name_sei_kanji:$person_name_sei_kanji,
                person_name_mei_kanji:$person_name_mei_kanji,
                person_name_sei_kana:$person_name_sei_kana,
                person_name_mei_kana:$person_name_mei_kana,
                buka_name_kanji:$buka_name_kanji,
                buka_name_kana:$buka_name_kana,
                person_tel:$person_tel,
                person_fax:$person_fax,
                person_email:$person_email,
                bank_code:$bank_code,
                branch_code:$branch_code,
                bank_name:$bank_name,
                branch_name:$branch_name,
                account_type:$account_type,
                account_number:$account_number,
                account_name:$account_name,
                bank_attachment:$bank_attachment,
                comission_person_name_sei_kanji:$comission_person_name_sei_kanji,
                comission_person_name_mei_kanji:$comission_person_name_mei_kanji,
                comission_person_name_sei_kana:$comission_person_name_sei_kana,
                comission_person_name_mei_kana:$comission_person_name_mei_kana,
                comission_person_tel:$comission_person_tel,
                comission_person_email:$comission_person_email,
                agreement_id:$agreement_id
                status:$status
              ){ id status }
          } `

    let statusstr = ''
    if(form.temporary_save == true) {
      statusstr = 'temporary'
    } else {
      statusstr = 'open'
    }

    const params = {
      regist_user_cognito_sub:store.state.user.attributes.sub,
      regist_user_name:store.state.user.attributes.name,
      regist_user_email:store.state.user.attributes.email,
      company_name_kanji:form.company_name_kanji,
      company_name_kana:form.company_name_kana,
      zip_cd:form.zip_cd,
      address_1_kanji:form.address_1_kanji,
      address_2_kanji:form.address_2_kanji,
      address_1_kana:form.address_1_kana,
      address_2_kana:form.address_2_kana,
      tel:form.tel,
      fax:form.fax,
      invoice_no:form.invoice_no,
      representative_name:form.representative_name,
      representative_title:form.representative_title,
      person_name_sei_kanji:form.person_name_sei_kanji,
      person_name_mei_kanji:form.person_name_mei_kanji,
      person_name_sei_kana:form.person_name_sei_kana,
      person_name_mei_kana:form.person_name_mei_kana,
      buka_name_kanji:form.buka_name_kanji,
      buka_name_kana:form.buka_name_kana,
      person_tel:form.person_tel,
      person_fax:form.person_fax,
      person_email:form.person_email,
      bank_code:form.bank_code,
      branch_code:form.branch_code,
      bank_name:form.bank_name,
      branch_name:form.branch_name,
      account_type:form.account_type,
      account_number:form.account_number,
      account_name:form.account_name,
      bank_attachment:paramBankAttachment,
      comission_person_name_sei_kanji:form.comission_person_name_sei_kanji,
      comission_person_name_mei_kanji:form.comission_person_name_mei_kanji,
      comission_person_name_sei_kana:form.comission_person_name_sei_kana,
      comission_person_name_mei_kana:form.comission_person_name_mei_kana,
      comission_person_tel:form.comission_person_tel,
      comission_person_email:form.comission_person_email,
      agreement_id:form.agreement_id,
      status:statusstr
    }

    const candidate = await API.graphql(graphqlOperation(createPartnerCandidateQuery, params))
    const id = candidate.data.createPartnerCandidate.id
    const status = candidate.data.createPartnerCandidate.status
    if(id){
      return {
        id: id,
        status: status,
        issent: true
      }
    } else {
      return null
    }
  },
  async updatePartnerCandidate (form) {
    let paramBankAttachment = [];
    const files = form.bank_attachment
    if(files) {
      for(let v of files) {
        const fileid = await this.uploadAttachment(v)
        let fileparam = {"id":fileid, "name":v.name}
        paramBankAttachment.push(fileparam)
      }
    }

    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $status: String,
            $org_status: String,
            $company_name_kanji: String,
            $company_name_kana: String,
            $zip_cd: String,
            $address_1_kanji: String,
            $address_2_kanji: String,
            $address_1_kana: String,
            $address_2_kana: String,
            $tel: String,
            $fax: String,
            $invoice_no: String,
            $representative_name: String,
            $representative_title: String,
            $person_name_sei_kanji: String,
            $person_name_mei_kanji: String,
            $person_name_sei_kana: String,
            $person_name_mei_kana: String,
            $buka_name_kanji: String,
            $buka_name_kana: String,
            $person_tel: String,
            $person_fax: String,
            $person_email: String,
            $bank_code: String,
            $branch_code: String,
            $bank_name: String,
            $branch_name: String,
            $account_type: String,
            $account_number: String,
            $account_name: String,
            $bank_attachment: [Attachment],
            $ex_bank_attachment: [Attachment],
            $comission_person_name_sei_kanji: String,
            $comission_person_name_mei_kanji: String,
            $comission_person_name_sei_kana: String,
            $comission_person_name_mei_kana: String,
            $comission_person_tel: String,
            $comission_person_email: String,
            $regist_user_email: String,
            $regist_user_name: String
          ) {
              updatePartnerCandidate(
                id:$id,
                status:$status,
                org_status:$org_status,
                company_name_kanji:$company_name_kanji,
                company_name_kana:$company_name_kana,
                zip_cd:$zip_cd,
                address_1_kanji:$address_1_kanji,
                address_2_kanji:$address_2_kanji,
                address_1_kana:$address_1_kana,
                address_2_kana:$address_2_kana,
                tel:$tel,
                fax:$fax,
                invoice_no:$invoice_no,
                representative_name:$representative_name,
                representative_title:$representative_title,
                person_name_sei_kanji:$person_name_sei_kanji,
                person_name_mei_kanji:$person_name_mei_kanji,
                person_name_sei_kana:$person_name_sei_kana,
                person_name_mei_kana:$person_name_mei_kana,
                buka_name_kanji:$buka_name_kanji,
                buka_name_kana:$buka_name_kana,
                person_tel:$person_tel,
                person_fax:$person_fax,
                person_email:$person_email,
                bank_code:$bank_code,
                branch_code:$branch_code,
                bank_name:$bank_name,
                branch_name:$branch_name,
                account_type:$account_type,
                account_number:$account_number,
                account_name:$account_name,
                bank_attachment:$bank_attachment,
                ex_bank_attachment:$ex_bank_attachment,
                comission_person_name_sei_kanji:$comission_person_name_sei_kanji,
                comission_person_name_mei_kanji:$comission_person_name_mei_kanji,
                comission_person_name_sei_kana:$comission_person_name_sei_kana,
                comission_person_name_mei_kana:$comission_person_name_mei_kana,
                comission_person_tel:$comission_person_tel,
                comission_person_email:$comission_person_email,
                regist_user_email:$regist_user_email,
                regist_user_name:$regist_user_name
              ){ id status }
          } `

    let statusstr = ''
    if(form.temporary_save == true) {
      statusstr = 'temporary'
    } else {
      statusstr = 'open'
    }

    const params = {
      id:form.id,
      status:statusstr,
      org_status:form.org_status,
      company_name_kanji:form.company_name_kanji,
      company_name_kana:form.company_name_kana,
      zip_cd:form.zip_cd,
      address_1_kanji:form.address_1_kanji,
      address_2_kanji:form.address_2_kanji,
      address_1_kana:form.address_1_kana,
      address_2_kana:form.address_2_kana,
      tel:form.tel,
      fax:form.fax,
      invoice_no: form.invoice_no,
      representative_name:form.representative_name,
      representative_title:form.representative_title,
      person_name_sei_kanji:form.person_name_sei_kanji,
      person_name_mei_kanji:form.person_name_mei_kanji,
      person_name_sei_kana:form.person_name_sei_kana,
      person_name_mei_kana:form.person_name_mei_kana,
      buka_name_kanji:form.buka_name_kanji,
      buka_name_kana:form.buka_name_kana,
      person_tel:form.person_tel,
      person_fax:form.person_fax,
      person_email:form.person_email,
      bank_code:form.bank_code,
      branch_code:form.branch_code,
      bank_name:form.bank_name,
      branch_name:form.branch_name,
      account_type:form.account_type,
      account_number:form.account_number,
      account_name:form.account_name,
      bank_attachment:paramBankAttachment,
      ex_bank_attachment:form.bank_attachment_filename,
      comission_person_name_sei_kanji:form.comission_person_name_sei_kanji,
      comission_person_name_mei_kanji:form.comission_person_name_mei_kanji,
      comission_person_name_sei_kana:form.comission_person_name_sei_kana,
      comission_person_name_mei_kana:form.comission_person_name_mei_kana,
      comission_person_tel:form.comission_person_tel,
      comission_person_email:form.comission_person_email,
      regist_user_email:form.regist_user_email,
      regist_user_name:form.regist_user_name
    }

    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))
    const id = candidate.data.updatePartnerCandidate.id
    const status = candidate.data.updatePartnerCandidate.status
    if(id){
      return {
        id: id,
        status: status,
        issent: true
      }
    } else {
      return null
    }
  },


  async getFirstPartnerCandidateHeaderByRegistUserSub(regist_user_cognito_sub) {
    this.resCandidates =  await this.getPartnerCandidateHeaderByRegistUserSub(regist_user_cognito_sub)
    this.headers = this.resCandidates.headers
    this.candidateHeaders = this.resCandidates.partnercandidates
    if(this.candidateHeaders) {
      if(this.candidateHeaders[0]) {
        return this.candidateHeaders[0]['id']
      } else {
        return null
      }
    } else {
      return null
    }
  },


  async getPartnerCandidateHeaderByRegistUserSub (regist_user_cognito_sub) {

    const listPartnerCandidate = `query getPartnerCandidate($regist_user_cognito_sub:String) {
      getPartnerCandidate(regist_user_cognito_sub:$regist_user_cognito_sub) {
        id
        status
        company_name_kanji
        create_datetime
        update_datetime
      }
    }`
    const params = {
      regist_user_cognito_sub: regist_user_cognito_sub
    }
    const partnercandidate = await API.graphql(graphqlOperation(listPartnerCandidate, params))
    return {
      search: '',
      headers: [
        { text: '受付番号', value: 'id' },
        { text: 'ステータス', value: 'status' },
        { text: '会社名', value: 'company_name_kanji'},
        { text: '申請日時', value: 'create_datetime', formatter: this.datetimeFormatter },
        { text: '', value: 'actions' },
      ],
      partnercandidates: partnercandidate.data.getPartnerCandidate
    }
  },
  async getPartnerCandidateById (id, regist_user_cognito_sub) {
    const listPartnerCandidate = `query getPartnerCandidate($id:String, $regist_user_cognito_sub:String) {
      getPartnerCandidate(id:$id, regist_user_cognito_sub:$regist_user_cognito_sub) {
        id
        regist_user_cognito_sub
        regist_user_name
        regist_user_email
        status
        memo
        company_id
        person_id
        bank_id
        comission_person_id
        create_datetime
        update_datetime
        supplier_cd
        customer_cd
        company_name_kanji
        company_name_kana
        zip_cd
        address_1_kanji
        address_2_kanji
        address_1_kana
        address_2_kana
        tel
        fax
        invoice_no
        representative_name
        representative_title
        person_name_sei_kanji
        person_name_mei_kanji
        person_name_sei_kana
        person_name_mei_kana
        buka_name_kanji
        buka_name_kana
        person_tel
        person_fax
        person_email
        bank_code
        branch_code
        bank_name
        branch_name
        account_type
        account_number
        account_name
        bank_attachment
        comission_person_name_sei_kanji
        comission_person_name_mei_kanji
        comission_person_name_sei_kana
        comission_person_name_mei_kana
        comission_person_tel
        comission_person_email
      }
    }`

    const params = {
      id: id,
      regist_user_cognito_sub: regist_user_cognito_sub
    }
    const partnercandidate = await API.graphql(graphqlOperation(listPartnerCandidate, params))
    const partnercandidateData = partnercandidate.data.getPartnerCandidate[0]

    let form = PartnerForm
    form.isviewform = true
    form.id= partnercandidateData.id
    form.regist_user_cognito_sub= partnercandidateData.regist_user_cognito_sub
    form.regist_user_name= partnercandidateData.regist_user_name
    form.regist_user_email= partnercandidateData.regist_user_email
    form.create_datetime= partnercandidateData.create_datetime
    form.update_datetime= partnercandidateData.update_datetime
    form.status= partnercandidateData.status
    form.company_name_kanji = partnercandidateData.company_name_kanji
    form.company_name_kana = partnercandidateData.company_name_kana
    form.zip_cd = partnercandidateData.zip_cd
    form.address_1_kanji = partnercandidateData.address_1_kanji
    form.address_2_kanji = partnercandidateData.address_2_kanji
    form.address_1_kana = partnercandidateData.address_1_kana
    form.address_2_kana = partnercandidateData.address_2_kana
    form.tel = partnercandidateData.tel
    form.fax = partnercandidateData.fax
    form.invoice_no = partnercandidateData.invoice_no
    form.representative_name = partnercandidateData.representative_name
    form.representative_title = partnercandidateData.representative_title
    form.person_name_sei_kanji = partnercandidateData.person_name_sei_kanji
    form.person_name_mei_kanji = partnercandidateData.person_name_mei_kanji
    form.person_name_sei_kana = partnercandidateData.person_name_sei_kana
    form.person_name_mei_kana = partnercandidateData.person_name_mei_kana
    form.buka_name_kanji = partnercandidateData.buka_name_kanji
    form.buka_name_kana = partnercandidateData.buka_name_kana
    form.person_tel = partnercandidateData.person_tel
    form.person_fax = partnercandidateData.person_fax
    form.person_email = partnercandidateData.person_email
    form.person_email_confirm = partnercandidateData.person_email
    form.bank_code = partnercandidateData.bank_code
    form.branch_code = partnercandidateData.branch_code
    form.bank_name = partnercandidateData.bank_name
    form.branch_name = partnercandidateData.branch_name
    form.account_type = partnercandidateData.account_type
    form.account_number = partnercandidateData.account_number
    form.account_name = partnercandidateData.account_name
    form.bank_attachment =[]
    form.bank_attachment_filename = JSON.parse(partnercandidateData.bank_attachment)
    form.comission_person_name_sei_kanji = partnercandidateData.comission_person_name_sei_kanji
    form.comission_person_name_mei_kanji = partnercandidateData.comission_person_name_mei_kanji
    form.comission_person_name_sei_kana = partnercandidateData.comission_person_name_sei_kana
    form.comission_person_name_mei_kana = partnercandidateData.comission_person_name_mei_kana
    form.comission_person_tel = partnercandidateData.comission_person_tel
    form.comission_person_email = partnercandidateData.comission_person_email
    form.comission_person_email_confirm = partnercandidateData.comission_person_email
    form.org_status= partnercandidateData.status

    return form
  },
  async getAttachmentDownloadPresignedUrl (fileid) {
    const getAttachmentDownloadPresignedUrl = `query getAttachmentDownloadPresignedUrl($id:String) {
          getAttachmentDownloadPresignedUrl(id:$id) {
            url
          }
        }`
    const params = {
      id: fileid
    }
    const presignedurl = await API.graphql(graphqlOperation(getAttachmentDownloadPresignedUrl, params))
    const file_url = presignedurl.data.getAttachmentDownloadPresignedUrl.url
    return file_url
  },

  async getAssetsPresignedUrl (filename) {
    const getAssetsPresignedUrl = `query getAssetsPresignedUrl($name:String) {
          getAssetsPresignedUrl(name:$name) {
            url
          }
        }`
    const params = {
      name: filename
    }
    const presignedurl = await API.graphql(graphqlOperation(getAssetsPresignedUrl, params))
    const file_url = presignedurl.data.getAssetsPresignedUrl.url
    return file_url
  },

  async agreeTerms (agreements) {
    const agreeTermsQuery = `
      mutation agreeTerms(
        $regist_user_cognito_sub: String,
        $agreement_channel: String,
        $terms: [Terms]
      ) {
          agreeTerms(
            regist_user_cognito_sub:$regist_user_cognito_sub,
            agreement_channel:$agreement_channel,
            terms:$terms,
          ){ id }
      } `

    const params = {
      regist_user_cognito_sub:store.state.user.attributes.sub,
      agreement_channel:'mepop-partner',
      terms:agreements,
    }
    let res = []
    const agreement = await API.graphql(graphqlOperation(agreeTermsQuery, params))
    for (const item of agreement.data.agreeTerms) {
      res.push(item['id'])
    }
    return res
  },


  datetimeFormatter (value) {
    if (value) {
      //return moment(value).format('YY/MM/DD HH:mm');
      return moment.utc(value).local().format('YY/MM/DD HH:mm');
    } else {
      return value;
    }
  },
}