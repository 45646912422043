function resetPartnerForm(form) {
    form.isviewform= false;
    form.istermagreed= false;
    form.agreement_id= [];
    form.issent= false;
    form.id= '';
    form.regist_user_cognito_sub= '';
    form.regist_user_name= '';
    form.regist_user_email= '';
    form.create_datetime= '';
    form.update_datetime= '';
    form.status= 'open';
    form.validation_supplier= false;
    form.validation_person= false;
    form.validation_bank= false;
    form.validation_invoiceperson= false;
    form.validation_confirm= false;
    form.company_name_kanji = '';
    form.company_name_kana = '';
    form.zip_cd = '';
    form.address_1_kanji = '';
    form.address_2_kanji = '';
    form.address_1_kana = '';
    form.address_2_kana = '';
    form.tel = '';
    form.fax = '';
    form.invoice_no = '';
    form.representative_name = '';
    form.representative_title = '';
    form.person_name_sei_kanji = '';
    form.person_name_mei_kanji = '';
    form.person_name_sei_kana = '';
    form.person_name_mei_kana = '';
    form.buka_name_kanji = '';
    form.buka_name_kana = '';
    form.person_tel = '';
    form.person_fax = '';
    form.person_email = '';
    form.person_email_confirm = '';
    form.bank_code = '';
    form.branch_code = '';
    form.bank_name = '';
    form.branch_name = '';
    form.account_type ='';
    form.account_number = '';
    form.account_name = '';
    form.bank_attachment = [];
    form.bank_attachment_filename= [];
    form.comission_person_name_sei_kanji = '';
    form.comission_person_name_mei_kanji = '';
    form.comission_person_name_sei_kana = '';
    form.comission_person_name_mei_kana = '';
    form.comission_person_tel = '';
    form.comission_person_email = '';
    form.comission_person_email_confirm = '';
    form.confirm_check= false;
    form.temporary_save= false;
    form.org_status = '';

    return form;
}

// 定義
export {
    resetPartnerForm
}
