var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.form.isviewform)?_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("銀行口座情報")])],1):_vm._e(),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"銀行コード","rules":"required|digits:4|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"銀行コード","placeholder":"例）0001","hint":"数字4桁で入力してください"},model:{value:(_vm.c_bank_code),callback:function ($$v) {_vm.c_bank_code=$$v},expression:"c_bank_code"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"支店コード","rules":"required|digits:3|max:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"支店コード","placeholder":"例）001","hint":"数字3桁で入力してください"},model:{value:(_vm.c_branch_code),callback:function ($$v) {_vm.c_branch_code=$$v},expression:"c_branch_code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-spacer')],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"11"}},[_c('v-alert',{staticClass:"text-caption",attrs:{"dense":"","outlined":"","type":"info","color":"indigo"}},[_vm._v(" 銀行コード、支店コードがご不明な場合は"),_c('v-btn',{staticClass:"pa-0 ma-0 text-decoration-underline text-caption",attrs:{"small":"","text":"","href":"http://bkichiran.hikak.com/","target":"_blank"}},[_vm._v("こちら")]),_vm._v("よりご確認ください ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"銀行名","rules":"required|kanji|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"銀行名","placeholder":"例）○○銀行、○○信用金庫"},model:{value:(_vm.c_bank_name),callback:function ($$v) {_vm.c_bank_name=$$v},expression:"c_bank_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"支店名","rules":"required|kanji|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"支店名","placeholder":"例）○○支店"},model:{value:(_vm.c_branch_name),callback:function ($$v) {_vm.c_branch_name=$$v},expression:"c_branch_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"預金種類","rules":"required|oneOf:savings,checking"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","readonly":!_vm.inputable,"items":_vm.accountTypes,"error-messages":errors,"required":"","label":"預金種類"},model:{value:(_vm.c_account_type),callback:function ($$v) {_vm.c_account_type=$$v},expression:"c_account_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"口座番号","rules":"required|digits:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"口座番号","placeholder":"例）0000001","hint":"数字7桁で入力してください。先頭が0の場合は0もご入力ください"},model:{value:(_vm.c_account_number),callback:function ($$v) {_vm.c_account_number=$$v},expression:"c_account_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"口座名義","rules":"required|bankaccountnamejp|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"口座名義","placeholder":"例）ｶ)ﾐｽﾐ","hint":"半角ｶﾅ30文字まで。濁点も1文字としてカウントされます"},model:{value:(_vm.c_account_name),callback:function ($$v) {_vm.c_account_name=$$v},expression:"c_account_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{staticClass:"ml-3 text-caption",attrs:{"dense":"","outlined":"","type":"warning","color":"deep-orange"}},[_vm._v(" 口座名義ご入力の際の注意事項"),_c('br'),_c('li',[_vm._v("株式会社は、前株→ ｶ)、後株→ (ｶ、中株→ (ｶ) で入力してください")]),_c('li',[_vm._v("有限会社は、前株→ ﾕ)、後株→ (ﾕ、中株→ (ﾕ) で入力してください")]),_c('li',[_vm._v("個人名の場合は、姓と名の間に半角ｽﾍﾟｰｽを空けてください")]),_c('li',[_vm._v("半角カナ小文字（ｧｨｩｪｫｯｬｭｮ）は大文字（ｱｲｳｴｵﾂﾔﾕﾖ）に変換されます")]),_c('li',[_vm._v("全角スペース、全角ハイフン、全角ダッシュは半角に変換されます")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[(!_vm.form.isviewform)?_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")]):_vm._e(),(_vm.form.isviewform)?_c('v-chip',{attrs:{"color":"grey","outlined":"","small":""}},[_vm._v("任意")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"添付ファイル","rules":_vm.getAttachmentRule()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"persistent-hint":"","outlined":"","disabled":!_vm.inputable,"error-messages":errors,"chips":"","multiple":"","label":"添付ファイル","hint":"不正送金防止のため、銀行情報がわかる請求書などのエビデンスの送付をお願いしております"},model:{value:(_vm.c_bank_attachment),callback:function ($$v) {_vm.c_bank_attachment=$$v},expression:"c_bank_attachment"}})]}}],null,true)})],1),(_vm.form.isviewform)?_c('v-col',{staticClass:"mt-4",attrs:{"cols":"1","align":"right"}},[_c('div',{staticClass:"grey--text text-caption"},[_vm._v("【登録済み】")])]):_vm._e(),(_vm.form.isviewform)?_c('v-col',{attrs:{"cols":"5"}},_vm._l((_vm.form.bank_attachment_filename),function(item){return _c('v-btn',{key:item.id,staticClass:"mr-2 mt-2 custom-transform-class text-none",attrs:{"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.downloadAttachment(item.id, item.name)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-file-table-outline")]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[(!_vm.form.isviewform)?_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0","color":"white"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("戻る")]):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"blue darken-4","outlined":"","type":"submit","disabled":invalid,"x-large":""}},[_vm._v(" "+_vm._s(_vm.nextbtntext())+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }