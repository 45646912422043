<template>
  <section class="home-hello">
    <v-container fluid fill-height class="home-hello__content">

      <v-row justify="center" dense class="home-hello__header">
        <v-card width="1280px" flat color="transparent">
          <v-row justify="space-between" dense>
            <div class="home_hello__header_logo">
              <img src="@/assets/mep_logo_black.png" />
            </div>
          </v-row>
        </v-card>
      </v-row>

      <v-row justify="center" dense>
        <v-card flat color="transparent" class="home_hello__firstview_content">
          <v-row justify="center" align-content="center" class="fill-height">
            <v-col class="ml-5">
              <v-row class="home-hello__content-title-text">ものづくりがつながる</v-row>
              <v-row class="text-subtitle-2 grey--text ml-0">初期費用￥0、月額利用料お試し12ヵ月間￥0、成約時販売手数料のみ</v-row>
              <v-row>
                <v-btn v-if="checkcandidateId && candidateId" x-large rounded elevation="0"
                  class="mt-2 mb-2 fill-btn-blue" @click="viewinfo(candidateId)">
                  登録情報のご確認
                </v-btn>
                <v-btn v-else-if="checkcandidateId" x-large rounded elevation="0" to="/registry"
                  class="mt-2 mb-2 fill-btn-blue">
                  パートナーに登録する
                </v-btn>
              </v-row>
              <v-row class="text-subtitle-2 grey--text ml-0" align-content="center" align="center">
                Hosted by <img src="@/assets/msm_logo_mini.png" alt="Misumi Corporation" />
              </v-row>

            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <v-row class="home_hello__overview_content">
        <v-sheet align="center" class="home_hello__overview_content-wrap">
          <v-col cols="10">
            <v-row justify="center">
              <v-col v-for="(item) of cards" v-bind:key="item.id" lg="4" md="4" sm="6"
                class="home_hello__overview_content-cards">
                <v-card class="home_hello__overview_content-card" align="left">
                  <v-card-title class="home_hello__overview_content-card-img">
                    <img height="88" v-bind:src="item.icon">
                  </v-card-title>
                  <v-card-title class="pa-10 pt-3 pb-0">
                    <div class="font-weight-black">
                      <div style="display: inline-block; _display: inline;" class="text-h4 font-weight-bold">{{
                        item.title1 }}</div>{{ item.title2 }}
                    </div>
                  </v-card-title>
                  <v-card-text class="pa-10 pt-3">{{ item.copy }}</v-card-text>
                </v-card>
              </v-col>
              <span class="beacon beacon1"></span>
              <span class="beacon beacon2"></span>
            </v-row>

            <v-row class="home_hello__overview_content-download">
              <v-col cols="12" align="center">
                <v-btn outlined rounded x-large class="mt-0 mb-16 line-btn-blue" @click="downloadGuidePDF()">
                  詳細資料をダウンロードする<v-icon>mdi-download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-sheet>
      </v-row>

      <div class="home_hello__footer_content">
        <v-row class="home_hello__footer_content-wrap">
          <v-col cols="4">
            <div class="home_hello__footer_logo">
              <img src="@/assets/mep_foot_logo_white.png" alt="meviy Marketplace ものづくりがつながる" />
            </div>
          </v-col>
          <v-col cols="8">
            <ul class="home_hello__footer_navigation">
              <li><a href="https://www.misumi.co.jp/company/">会社概要</a></li>
              <li><a href="https://jp.misumi-ec.com/">MISUMI-VONA</a></li>
              <li><a href="https://meviy.misumi-ec.com/">meviy</a></li>
            </ul>
          </v-col>
          <div class="home_hello__footer_address">
            <address>Copyright &copy; MISUMI Corporation All Rights Reserved.</address>
          </div>
        </v-row>
      </div>

    </v-container>
  </section>
</template>
<style lang="scss" scoped>
.home-hello__content {
  width: 100%;
  padding: 0;

  &-text {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }

  &-title-text {
    color: #333;
    text-align: left;
    font-size: 60px;
    font-weight: bold;
  }

}

/* ヘッダーナビゲーション */
.home-hello__header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.8);
}

.home_hello__header_logo {
  margin-top: 12px;
}

/* ボタン */
.line-btn-blue {
  border: solid 2px #0F218B;
  background: #fff;
  color: #333;
  font-weight: bold;
  -webkit-transition: all .3s;
  transition: all .3s;

  &>span>i {
    color: #0F218B !important;
  }

  &:hover {
    background: #0F218B;
    color: #fff;
    opacity: 1;
  }

  &:hover>span>i {
    color: #fff !important;
  }
}

.fill-btn-blue {
  border: solid 2px #0F218B;
  background-color: #0F218B !important;
  color: #FFF !important;
  font-weight: bold;
  -webkit-transition: all .3s;
  transition: all .3s;

  &>span>i {
    color: #FFF;
  }

  &:hover::before {
    opacity: 0 !important;
  }

  &:hover {
    border: solid 2px #0F218B;
    background-color: #FFF !important;
    color: #0F218B !important;
    opacity: 1;
  }

  &:hover>span>i {
    color: #0F218B !important;
  }
}

/* コンテンツ */
.home_hello__firstview_content {
  width: 1280px;
  height: 350px;
  padding-top: 68px;
  /*ヘッダナビ分*/
  background: url("../assets/top_illust.jpg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right 0% bottom 2%;
}

.home_hello__overview_content {
  margin-top: 3px !important;

  .home_hello__overview_content-wrap {
    width: 100%;
    background-color: #CDEBF0;

    &>.col {
      width: 1280px;
      min-width: 1280px;
      height: 760px;
      background-image: url("../assets/top_3column_bg_curve.png");
      background-repeat: no-repeat;
      background-position: top 50px left 0px;
    }

    .home_hello__overview_content-cards {
      margin-top: 70px;

      .home_hello__overview_content-card {
        width: 340px;
        height: 460px;
        border-radius: 167px;

        &>.v-card__title {
          padding: 60px 12px 12px;
        }

        &>.home_hello__overview_content-card-img {
          padding: 60px 122px 32px;
        }
      }
    }

    & .row {
      position: relative;
    }

    .beacon {
      display: block;
      position: absolute;
      background-color: #5bbdce;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      -webkit-animation: grow .4s 1 linear;
      animation: grow .4s 1 linear;
      -webkit-transform: translateX(-50%) translateY(-50%);
    }

    .beacon:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0;
      top: 0;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: 0px 0px 2px 2px #5bbdce;
      -webkit-animation: active 2s infinite linear;
      animation: active 2s infinite linear;
    }

    .beacon1 {
      top: 324px;
      left: 427px;
    }

    .beacon2 {
      top: 324px;
      left: 852px;
    }

    @-webkit-keyframes grow {
      0% {
        -webkit-transform: scale(.1);
      }

      100% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes grow {
      0% {
        transform: scale(.1);
      }

      100% {
        transform: scale(1);
      }
    }

    @-webkit-keyframes active {
      0% {
        -webkit-transform: scale(.1);
        opacity: 1;
      }

      70% {
        -webkit-transform: scale(2.5);
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes active {
      0% {
        -webkit-transform: scale(.1);
        opacity: 1;
      }

      70% {
        -webkit-transform: scale(2.5);
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }

    .home_hello__overview_content-download {
      margin-top: 88px;
    }

  }

}

/* フッターナビゲーション */
.home_hello__footer_content {
  width: 100%;
  padding: 40px 0;
  background: #222;
}

.home_hello__footer_content-wrap {
  width: 1280px;
  margin: 0 auto;

  &>.col {
    padding: 0 12px;
  }
}

.home_hello__footer_logo {
  margin-top: 20px;
}

.home_hello__footer_navigation {
  list-style: none;
  padding-bottom: 24px;

  &>li {
    line-height: 50px;
  }

  &>li>a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
  }

  &>li>a:hover {
    color: #2ea0b4;
  }
}

.home_hello__footer_address {
  width: 1280px;
  padding-top: 24px;
  border-top: 1px solid #707070;

  &>address {
    font-style: normal;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
}
</style>

<script>
import candidateService from "@/services/candidateService";
import store from "@/store";
import mepoputil from "@/util/download";
export default {
  name: 'Hello',
  title: 'パートナー登録申請サイト',
  data: () => ({
    candidateId: null,
    checkcandidateId: false,
    cards: [
      {
        id: '1',
        title1: '顧客',
        title2: 'とつながる',
        copy: 'ミスミで標準品の調達を行う世界34万社の設計者、購買者と直接つながり、具体的なニーズに応じて様々なサービスを提供できます',
        icon: require('@/assets/top_icon_meeting.png'),
      },
      {
        id: '2',
        title1: '価値',
        title2: 'につながる',
        copy: 'サービスを選んで頂いてから直接具体的案件が始まり、ダイレクトコミュニケーションが行えるので極めて効率的。全ての時間を高付加価値創出の時間に充てられます',
        icon: require('@/assets/top_icon_gear.png'),
      },
      {
        id: '3',
        title1: 'Digital',
        title2: 'につながる',
        copy: '顧客とDigitalでつながると、どんなに離れていても情報品質劣化ゼロ、伝達時間ゼロ、伝達コストゼロ、待ち時間ゼロ。コミュニケーションコストが大幅に削減されます',
        icon: require('@/assets/top_icon_digital.png'),
      },
    ],
    logo: require('@/assets/misumilogo.png'),
  }),
  methods: {
    viewinfo(val) {
      this.$router.push({ name: "ViewInfo", params: { id: val } }).catch(() => { });
    },
    async downloadGuidePDF() {
      await mepoputil.downloadGuidePDF()
    }
  },
  async mounted() {
    await this.$nextTick(async function () {
      this.candidateId = await candidateService.getFirstPartnerCandidateHeaderByRegistUserSub(store.state.user.attributes.sub)
      this.checkcandidateId = true
    })
  }
}
</script>
