<template>
  <v-container>

    <v-row class="text-center">
      <v-col class="mb-4" cols="10">
        <h2 v-if="form.status=='open'" class="font-weight-bold mb-3">
            登録申請を承りました。
        </h2>
        <h2 v-else-if="form.status=='temporary'" class="font-weight-bold mb-3">
          一時保存を承りました。
        </h2>
        <p class="subtitle-1">
          お問い合わせの際には、以下の受付番号と申請された方のログイン用メールアドレスをお知らせください。<br>
          受付番号はこちらのサイトでいつでも確認できます。
        </p>

        <v-simple-table align="center">
          <tbody>
          <tr bgcolor="#f5f5f5"><th width="40%" class="text-right">受付番号</th><td class="font-weight-bold">{{ form.id }}</td></tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4" cols="10">
        <v-btn color="indigo" outlined class="mr-4" @click="viewinfo(form.id)">申請内容確認</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import '@mdi/font/css/materialdesignicons.css'
export default {
  name: 'Registry',
  title: '送信完了',
  props: {
    form: PartnerForm,
  },
  data: () => ({
  }),
  methods: {
    viewinfo(val) {
      this.$router.push({ name: "ViewInfo" , params: {id : val}}).catch(() => {});
    },
  },
  mounted : function(){
    if(this.form.id && !this.form.issent) {
      this.$router.push('/viewlist')
    } else if(!this.form.issent) {
      this.$router.push('/')
    }
    this.form.issent = false
  }
}
</script>
