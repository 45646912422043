import { Translations } from '@aws-amplify/ui-components'
export const cognito_messages = {
    "ja": {
        [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'ログインID',
        [Translations.USERNAME_LABEL]: 'ログインID *',
        [Translations.USERNAME_PLACEHOLDER]: 'ログインIDを入力',
        [Translations.CODE_LABEL]: "認証コード",
        [Translations.CODE_PLACEHOLDER]: 'コードを入力',
        [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: '認証コード',
        [Translations.PASSWORD_LABEL]: 'パスワード',
        [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力',
        [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
        [Translations.NEW_PASSWORD_PLACEHOLDER]: '新しいパスワードを入力',
        [Translations.ADDRESS_LABEL]: '住所',
        [Translations.ADDRESS_PLACEHOLDER]: '住所を入力',
        [Translations.NICKNAME_LABEL]: 'ニックネーム',
        [Translations.NICKNAME_PLACEHOLDER]: 'ニックネームを入力',
        [Translations.BIRTHDATE_LABEL]: '誕生日',
        [Translations.BIRTHDATE_PLACEHOLDER]: '誕生日を入力',
        [Translations.PICTURE_LABEL]: '写真のURL',
        [Translations.PICTURE_PLACEHOLDER]: '写真のURLを入力',
        [Translations.FAMILY_NAME_LABEL]: '苗字',
        [Translations.FAMILY_NAME_PLACEHOLDER]: '苗字を入力',
        [Translations.PREFERRED_USERNAME_LABEL]: '呼び名',
        [Translations.PREFERRED_USERNAME_PLACEHOLDER]: '呼び名を入力',
        [Translations.GENDER_LABEL]: '性別',
        [Translations.GENDER_PLACEHOLDER]: '性別を入力',
        [Translations.PROFILE_LABEL]: 'プロフィールURL',
        [Translations.PROFILE_PLACEHOLDER]: 'プロフィールURLを入力',
        [Translations.GIVEN_NAME_LABEL]: '名前',
        [Translations.GIVEN_NAME_PLACEHOLDER]: '名前を入力',
        [Translations.ZONEINFO_LABEL]: 'タイムゾーン',
        [Translations.ZONEINFO_PLACEHOLDER]: 'タイムゾーンを入力',
        [Translations.LOCALE_LABEL]: 'ロケール',
        [Translations.LOCALE_PLACEHOLDER]: 'ロケールを入力',
        [Translations.UPDATED_AT_LABEL]: '更新日時',
        [Translations.UPDATED_AT_PLACEHOLDER]: '情報が最後に更新された時刻を入力してください',
        [Translations.MIDDLE_NAME_LABEL]: 'ミドルネーム',
        [Translations.MIDDLE_NAME_PLACEHOLDER]: 'ミドルネームを入力',
        [Translations.WEBSITE_LABEL]: 'ウェブサイト',
        [Translations.WEBSITE_PLACEHOLDER]: 'ウェブサイトを入力',
        [Translations.NAME_LABEL]: 'フルネーム',
        [Translations.NAME_PLACEHOLDER]: 'フルネームを入力',
        "Custom auth lambda trigger is not configured for the user pool.":
            "パスワードが入力されていません",
        "Cannot reset password for the user as there is no registered/verified email or phone_number":
            "メールアドレスや電話番号が登録されていないため、ユーザーのパスワードをリセットできません。",
        "Incorrect username or password.": "ログインIDまたはパスワードが異なります",
        "Username cannot be empty": "ログインIDを入力してください",
        "Enter your Username": "ログインIDを入力",
        "Enter new password": "新しいパスワードを入力",
        "New Password": "新しいパスワード",
        "Name": "氏名"
    },
};